import { decodeToken, isExpired } from "react-jwt";

export const mydecodedTokenFunction = (token) => {
    let decodedToken = null;
    if (!token) return decodedToken
    if (!isExpired(token)) {
        decodedToken = decodeToken(token);
    }
    return decodedToken;
};

export default mydecodedTokenFunction;

