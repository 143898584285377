import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { modifiedContent } from "../Utils/Util";
import { Card, Tag, Tooltip } from "antd";

function ProjectScopeLeftSection({ datas }) {
  const [projectDetail, setProjectDetail] = useState({});
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    setProjectDetail({ ...datas });
    setLoading(false);
  }, [datas]);

  if (!Loading) {
    function RemoveHtml(myString) {
      if (myString !== undefined) {
        return myString.replace(/(<([^>]+)>)/gi, "");
      }
    }

    return (
      <Card
        className="projectOverview gap-2"
        title={
          <>
            <h5
              className="mt-0 pt-0 mb-0"
              style={{ color: "#5746EB" }}
            >
              {projectDetail?.project_title}
            </h5>
            <p className="mt-2">
              {projectDetail?.country
                ?.split(",")
                ?.map((country, index) => (
                  <Tag
                    className="cust_tag"
                    color="purple"
                    key={index}
                  >
                    {" "}
                    {country}
                  </Tag>
                ))}
            </p>
          </>
        }
      >
        <p
          className="custom_style"
          dangerouslySetInnerHTML={{
            __html: modifiedContent(projectDetail?.question),
          }}
        ></p>

        {projectDetail?.attachments?.length > 0 && (
          <div
            style={{
              marginTop: "20px",
              paddingLeft: "10px",
            }}
          >
            {projectDetail?.attachments?.map((attach) => {
              return (
                <div className="attachment_overview">
                  <a
                    href={attach?.s3url}
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center gap-1"
                  >
                    {/* <GrAttachment /> */}
                    {attach?.file_original_name}
                  </a>
                </div>
              );
            })}
          </div>
        )}
      </Card>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }
}

export default ProjectScopeLeftSection;
