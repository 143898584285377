export function CalculateExpertRate({ rate }) {
  if (rate == 0 || rate === null || rate === "" || rate === undefined) {
    return 550;
  } else {
    if (rate >= 1 && rate <= 200) {
      return 450;
    } else if (rate >= 201 && rate <= 300) {
      return 550;
    } else if (rate >= 301 && rate <= 400) {
      return 650;
    } else if (rate >= 401 && rate <= 500) {
      return 700;
    } else if (rate >= 501 && rate <= 600) {
      return 800;
    } else if (rate >= 601 && rate <= 700) {
      return 900;
    } else if (rate >= 701 && rate <= 800) {
      return 1000;
    } else if (rate >= 801 && rate <= 900) {
      return 1150;
    } else if (rate >= 901 && rate <= 999) {
      return 1250;
    } else if (rate >= 1000 && rate <= 1099) {
      return 1350;
    } else if (rate >= 1100 && rate <= 1199) {
      return 1500;
    } else if (rate >= 1200 && rate <= 1299) {
      return 1650;
    } else if (rate >= 1300 && rate <= 1399) {
      return 1750;
    } else if (rate >= 1400 && rate <= 1499) {
      return 1850;
    } else {
      return rate * 1.25;
    }
  }
}
