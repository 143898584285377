import { Select, Modal } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import UserMangementTable from "./UserMangementTable";
import { Col, Row } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { Form } from "antd";
import { countryOptions } from "../Utils/Util";
import UserModal from "./UserModal";

const { Option } = Select;



const UserMangement = () => {
  document.title = "Client | User Management";

  let UserData = JSON.parse(localStorage.getItem("userData"));

  const [visible, setVisible] = useState(false);
  const InputType = visible ? "text" : "password";
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [spinner, setSpinner] = useState(false);
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [phone, setPhone] = useState("");
  const [showCreateModal, setCreateModal] = useState(false);
  const [form] = Form.useForm();

  // const [formModal, setFormModal] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const history = useNavigate();
  const [data, setUserData] = useState([]);
  const [addUser, setAddUser] = useState({
    firstNmae: "",
    email: "",
    contact_code: "",
    contact_number: "",
    country: "",
    password: "",
    role_id: "",
    registrationType: "client"
  });
  const [message, setMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    axios
      .get(`${API_PATH.USER_MANAGEMENT}/${UserData.user.company_id._id}`, {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        setLoading(true);
        setUserData([...res.data.data]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setAddUser({ ...addUser, [key]: value });
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    // const contact_code = country?.dialCode;
    // const contact_number = value?.replace(country?.dialCode, "");

    // const isValidContact = contact_code && contact_number;

    if (selectedCountry) {
      setAddUser((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        contact_number: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));

      // setContactValid(isValidContact);
    } else {
      console.info("No matching country found for dial code:", country.dialCode);
    }
  };

  const handleSubmit = async () => {
    const { firstNmae, email, role_id } = addUser;
    if (firstNmae !== "" && email !== "" && phone !== "" && role_id !== "") {
      const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regEx.test(addUser?.email?.toLowerCase())) {
        return setMessage("Email is invalid");
      } else {
        setMessage("");
      }

      setSpinner(true);

      addUser.email = addUser?.email?.toLowerCase();

      fetch(`${API_PATH.USER_MANAGEMENT_POST}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(addUser),
      })
        .then((res) => {
          getUsers();
          setSpinner(false);

          //navigate("/privacy")
          if (res.status === 200) {
            toast.success("User added successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            // window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const getUserList = async () => {
    try {
      const res = await axios.get(
        `${API_PATH.ROLE_MANAGEMENT}?company_id=${userData?.user?.company_id?._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const response = res.data;
      setRoleList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  //   const deleteUser = async (data) => {
  //     e.preventDefault()
  //     try {

  //         const res = await axios.delete(`${API_PATH.USER_MANAGEMENT_DELETE}/${data}`, {
  //           getUsers()
  //         })

  //         if (res.data.status === 200) {
  //             history.push("/home")
  //             toast.success("UserData Updated Successful", {
  //                 position: toast.POSITION.TOP_RIGHT,
  //                 autoClose: 3000
  //             })
  //         }
  //     } catch (error) {
  //         return toast.error(error.response.data.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //             autoClose: 3000
  //         })
  //     }

  // }

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const deleteUser = async (data) => {
    axios
      .delete(`${API_PATH.USER_MANAGEMENT_DELETE}/${data}`, {
        method: "DELETE",
        headers: headers,
      })
      .then((res) => {
        getUsers();
        if (res) {
          if (res?.data?.success === true) {
            toast.success("User deleted successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {
              // window.location.reload();
            }, 3000);
          } else {
            if (
              res?.data?.success === false &&
              res?.data?.message === "Your account has been saved"
            ) {
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            } else {
              toast.error(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          }
        }
      })
      .catch((error) => {
        toast.error(error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages expert-cell-page">
                <div className="d-flex top-ps-seection justify-content-between align-items-center">
                  <h2> Users </h2>

                  <button
                    className="btn viewButton"
                    // data-bs-toggle="modal"
                    // data-bs-target="#adduser"
                    onClick={() => setCreateModal(true)}
                  >
                    <BsPlusLg />Add New
                  </button>
                </div>

                <div className="managment-table">
                  <UserMangementTable
                    data={data}
                    deleteUser={deleteUser}
                    load={loading}
                    getUsers={getUsers}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        className="modal fade pro-jects1"
        id="carteProjectModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-ps-modal-1">
                <h2 className="text-center"> Create New Project </h2>
                <p className="text-center">
                  {" "}
                  Detail out your reqiurement to help us match you with the best
                  in the industry{" "}
                </p>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label> Project title * </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Project name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Number of expected calls *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value>Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Project deadline *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value>Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label> Project Overview * </label>
                      <div className="mainl">
                        <textarea
                          className="form-control"
                          placeholder="Project brief"
                        ></textarea>
                        <figure className="mainl-c">
                          <img src="/images/mail-pic.png" />
                        </figure>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#projects-modal"
                      data-bs-dismiss="modal"
                      className="btn col-lg-5 mx-auto btnsm"
                    >
                      {" "}
                      Create{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserModal showCreateModal={showCreateModal} setCreateModal={setCreateModal} getUsers={getUsers} type="create" />

      {/* <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setCreateModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Add User</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="projects-modal-divs">
                
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label> Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstNmae"
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Email Address *</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        onChange={handleInputs}
                      />
                    </div>
                    {message === "" ? (
                      <></>
                    ) : (
                      <p className="text-danger">{message}</p>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label> Contact Number * </label>
                      <div>
                        <PhoneInput
                          country={"us"}
                          enableSearch={true}
                          value={phone}
                          dropdownStyle={{
                            position: "fixed",
                          }}
                          onChange={(value, country) => {
                            handlePhoneChange(value, country);
                          }}
                          inputStyle={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Role *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleInputs}
                        name="role_id"
                      >
                        <option value> select</option>
                        {roleList?.map((option, index) => (
                          <option value={option._id} key={index}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setCreateModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => {
                setCreateModal(false);
                handleSubmit();
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* // add user modal */}
    </>
  );
};

export default UserMangement;
