import React, { useState, useEffect } from "react";

function ScopeOverview({ datas }) {
  const [projectOverview, setProjectOverview] = useState({});
  const [projectOverview1, setProjectOverview1] = useState({});
  useEffect(() => {
    setProjectOverview({ ...datas });
    setProjectOverview1({ ...datas });
  }, [datas]);

  function CalculatePercentage(total, percentage) {
    return parseInt((percentage / total) * 100);
  }

  const completedCalls = parseFloat(projectOverview1.completed_Calls);

  const percentage = CalculatePercentage(
    projectOverview.expected_calls,
    completedCalls
  );

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="progress col-10 mb-0">
          <div
            className="progress-bar"
            style={{ width: `${percentage}%` }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <h5 className="my-0">{percentage + "%"}</h5>
      </div>
      {/* <hr /> */}
      <div className="d-flex align-items-center justify-content-between">
        <ul className="list-unstyled right-small-icons d-flex align-items-center justify-content-between w-100 mb-0">
          <li className="text-center" style={{ display: "unset" }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="me-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  color="red"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "red", fontSize: "18px" }}
                >
                  <path
                    fill="none"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z"
                  ></path>
                </svg>
              </span>
              <span
                style={{ position: "relative", top: "1px", fontSize: "18px" }}
              >
                {projectOverview?.expected_calls
                  ? projectOverview?.expected_calls
                  : 0}
              </span>
            </div>
            <div className="mt-2">
              <small className="d-block">
                Expected <br />
                Calls
              </small>
            </div>
          </li>

          <li className="text-center" style={{ display: "unset" }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="me-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  color="red"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "red", fontSize: "18px" }}
                >
                  <g id="File_On">
                    <path d="M19.485,7.35l-4.97-4.86a1.466,1.466,0,0,0-1.05-.43h-6.9a2.5,2.5,0,0,0-2.5,2.5V19.44a2.507,2.507,0,0,0,2.5,2.5h10.87a2.507,2.507,0,0,0,2.5-2.5V8.42A1.49,1.49,0,0,0,19.485,7.35Zm-1.27.15h-2.34a1.5,1.5,0,0,1-1.5-1.5V3.75Zm.72,11.94a1.5,1.5,0,0,1-1.5,1.5H6.565a1.5,1.5,0,0,1-1.5-1.5V4.56a1.5,1.5,0,0,1,1.5-1.5h6.81V6a2.5,2.5,0,0,0,2.5,2.5h3.06Z"></path>
                  </g>
                </svg>
              </span>
              <span
                style={{ position: "relative", top: "1px", fontSize: "18px" }}
              >
                {projectOverview1?.expected_Submitted
                  ? projectOverview1?.expected_Submitted
                  : 0}
              </span>
            </div>
            <div className="mt-2">
              <small className="d-block">
                Profiles
                <br />
                Submitted
              </small>
            </div>
          </li>

          <li className="text-center" style={{ display: "unset" }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="me-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  color="red"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "red", fontSize: "18px" }}
                >
                  <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>
                </svg>
              </span>
              <span
                style={{ position: "relative", top: "1px", fontSize: "18px" }}
              >
                {projectOverview1?.completed_Calls
                  ? projectOverview1?.completed_Calls
                  : 0}
              </span>
            </div>
            <div className="mt-2">
              <small className="d-block">
                Completed
                <br />
                calls
              </small>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
export default ScopeOverview;
