import React from "react";
export function CurrencyIcon({ currencyType }) {
  switch (currencyType) {
    case "USD":
      return "$";
      break;
    case "GBP":
      return "£";
      break;
    case "EUR":
      return "€";
      break;
    case "INR":
      return "₹";
      break;
    default:
      return "$";
  }
}
