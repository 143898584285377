import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
    name: 'project',
    initialState: {
        selectedProject: {}
    },
    reducers: {
        setSelectedProject: (state, { payload }) => {
            state.selectedProject = payload
        }
    },

});

export const { setSelectedProject } = projectSlice.actions;

export default projectSlice.reducer;