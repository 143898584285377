import { authReducer } from "../features/auth.slice"
import transcriptChatReducer from "../features/TranscriptChat.slice"
import projectReducer from "../features/project.slice"

const reducers = {
    auth: authReducer,
    transcriptChatReducer: transcriptChatReducer,
    projectReducer: projectReducer,

}

export default reducers
