import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { NavLink } from "react-router-dom";
import "./Login.css";
import LoginForm from "./LoginForm";
import nextyn_logo from "../assests/images/NX19.png"
import nextyn_logo2 from "../assests/images/Nextyn_logo.png"

function Login() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });

  document.title = "Client | Login";
  return (
    <>
      <div className="login-page">
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col d-none d-lg-block">
              <div className="login-left-part">
                <NavLink to="/" className="logo">
                  <img src={nextyn_logo} alt="logo" style={{ width: "35%" }} />
                </NavLink>
                <div className="logo-in-div col-lg-8 mx-auto">
                  <h1
                    className="text-left text-white loginText"
                    data-aos="fade-down"
                  >
                    The easiest way for businesses to collaborate with the
                    world's leading experts.
                  </h1>
                </div>

                <div className="col-lg-7 mx-auto">
                  <div className="img-box-1 mt-4">
                    <img src="images/Login.gif"></img>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="position-relative d-flex align-items-center justify-content-center"
              style={{ overflowY: "scroll" }}
            >
              <div className="comon-fild col-lg-8">
                <div className="inside-login">
                  <NavLink to="/" className="logo d-block d-lg-none mb-5">
                    <img src={nextyn_logo2} alt="logo" />
                  </NavLink>
                  <h3>Login</h3>
                  <p style={{ color: "#5746ec" }}>You're just one step away!</p>
                  <LoginForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
