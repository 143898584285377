import axios from "axios";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./App.css";
import ChangePassword from "./ChangePassword/ChangePassword";
// import ChatGpt from "./UnwantedFilesAndFolder/ChatGPT/ChatGpt.js";
import Collaborate from "./Collaborate/Collaborate";
// import CollaborateCreateNotes from "./UnwantedFilesAndFolder/CollaborateCreateNotes.js";
// import CollaborateSearch from "./UnwantedFilesAndFolder/CollaborateSearch.js";
// import CreateNotesForm from "./UnwantedFilesAndFolder/CreateNotes.js";
// import FolderView from "./UnwantedFilesAndFolder/FolderView.js";
// import Conversations from "./UnwantedFilesAndFolder/Conversations/Conversations.js";
import ExpertProfile from "./Dashboard/ExpertProfilePage";
// import ExpertCell from "./UnwantedFiles/Expert_cell/ExpertCell.js";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import Login from "./Login/Login";
// import News from "./News/News";
import NotificationPage from "./notifications/notification";
import UserProfile from "./profile/UserProfile";
import Projects from "./Projects/Projects";
import ProjectsDetails from "./Projects/ProjectsDetails";
import ProjectsDetailsExpert from "./Projects/ProjectsDetailsExpert";
import ProjectsDetailsMessage from "./Projects/ProjectsDetailsMessage";
import ResetPassword from "./ResetPassword/ResetPassword";
// import ShareFolderView from "./UnwantedFilesAndFolder/ShareFolderView.js";
// import ShareRecentView from "./UnwantedFilesAndFolder/ShareRecentView.js";
// import ShareWithMe from "./UnwantedFilesAndFolder/ShareWithMe.js";
// import TranscriptDetails from "./share/ShareTranscriptDetail.js";
import HelpSupport from "./Support/HelpSupport";
import AddRoleManagement from "./User_management/AddRoleManagement";
import RolesManagement from "./User_management/RolesMangement";
// import UpdateRoleManagement from "./UnwantedFilesAndFolder/UpdateRoleManagement.js";
import UserMangement from "./User_management/UserMangement";
// import Demo2 from "./demo/Demo2.js";

// expert
// import RecentView from "./UnwantedFilesAndFolder/RecentView.js";
// import ExpertCallHistory from "./UnwantedFilesAndFolder/ExpertCall/ExpertCallHistory.js";
// import ExpertCompliance from "./UnwantedFilesAndFolder/ExpertCompliance/ExpertCompliance.js";
// import ExpertDashboard from "./expert/ExpertDashboard/ExpertDashboard";
// import ExpertProjectDetails from "./expert/ExpertDashboard/ExpertProjectDetails";
// import ExpertLogin from "./UnwantedFilesAndFolder/ExpertLogin/ExpertLogin.js";
// import ExpertPayment from "./UnwantedFilesAndFolder/ExpertPayment.js/ExpertPayment.js";
// import ExpertRegister from "./UnwantedFilesAndFolder/ExpertRegister/ExpertRegister.js";
// import LandingRegister from "./UnwantedFilesAndFolder/ExpertRegister/LandingRegister.js";
// import {useLocation } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./assests/css/customResponsive.css";
// import EditCategories from "./UnwantedFilesAndFolder/Categories/EditCategories.js";
import TranscriptDetail from "./Collaborate/TranscriptDetail";
import ErrorPage from "./components/ErrorPage";
import ExpiredPage from "./components/ExpiredPage";
import Dashboard from "./Dashboard/Dashboard";
// import Starred from "./Dashboard/Dashboard2";
// import Fullcalendar from "./FullCalender/Fullcalendar";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Calendly from "./Register/Calendly.js";
import GetDemo from "./Register/GetDemo.js";
// import RegisterStepOne from "./UnwantedFilesAndFolder/Register/RegisterStepOne";
// import SharedContent from "./UnwantedFilesAndFolder/SharedContents.js";
// import SharedNotes from "./UnwantedFilesAndFolder/SharedNotes.js";
import OtpVerify from "./VerifyOtp/OtpVerify";
import OtpVerifyDemo from "./VerifyOtp/OtpVerifyDemo";

import MainDashboard from "./MainDashboard/Dashboard";
import { history } from "./Utils/history.js";
import useDecodedToken from "./Utils/useDecodedToken.js";
import React from "react";
import PrivateRouteTwo from "./PrivateRoute/PrivateRouteTwo.jsx";

function App() {
  const decodedToken = useDecodedToken();
  history.navigate = useNavigate();
  history.location = useLocation();

  let isAuth = JSON.parse(localStorage.getItem("isAuth"));

  if (!isAuth) {
    const pathname = window.location.pathname;
    localStorage.setItem("redirectPath", pathname + window.location.search);
  }
  React.useEffect(() => {
    if (!decodedToken) {
      localStorage.removeItem("userData");
      localStorage.removeItem("isAuth");
      history.navigate("/");
    }
  }, [decodedToken]);

  // const history = useNavigate();
  // const [tokenExpired, setTokenExpired] = useState(false);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     const token = localStorage.getItem("userData");
  //     if (token) {
  //       const tokenExpiration = JSON.parse(token).exp;
  //       if (tokenExpiration < Date.now() / 1000) {
  //         localStorage.removeItem("userData");
  //         setTokenExpired(true);
  //       }
  //     }
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, []);

  // useEffect(() => {
  //   if (tokenExpired) {
  //     return <Navigate to="/" replace />;
  //   }
  // }, [tokenExpired]);

  axios.interceptors.response.use(
    (response) => {
      if (response.data.status === 402 || response.data.status === 403) {
        localStorage.clear();
        history.navigate("/");
      }
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        history.navigate("/");
      }
      return Promise.reject(error);
    }
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRouteTwo token={isAuth}>
            <Login />
          </PrivateRouteTwo>
        }
      />
      {/* <Route path="/ckeditor" element={<mple />} /> */}
      {/* <Route exact="true" path="/register" element={<Register />} /> */}
      <Route
        exact="true"
        path="/forgot-password"
        element={<ForgotPassword />}
      />
      <Route exact="true" path="/reset-password" element={<ResetPassword />} />
      <Route
        exact="true"
        path="/dashboard"
        element={
          <PrivateRoute token={isAuth}>
            <MainDashboard />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/find-expert"
        element={
          <PrivateRoute token={isAuth}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/projects"
        element={
          <PrivateRoute token={isAuth}>
            <Projects />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/expert-profile"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertProfile />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/projects-details"
        element={
          <PrivateRoute token={isAuth}>
            <ProjectsDetails />
          </PrivateRoute>
        }
      />
      {/* <Route
        exact="true"
        path="/call_recordings/call_recordings-search-details"
        element={
          <PrivateRoute token={isAuth}>
            <CollaborateSearch />
          </PrivateRoute>
        }
      /> */}
      <Route
        exact="true"
        path="/call_recordings"
        element={
          <PrivateRoute token={isAuth}>
            <Collaborate />
          </PrivateRoute>
        }
      />
      {/* <Route
        exact="true"
        path="/collaborate"
        element={
          <PrivateRoute token={isAuth}>
            <Collaborate />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/demo2"
        element={
          <PrivateRoute token={isAuth}>
            <Demo2 />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/call_recordings/notes-details"
        element={
          <PrivateRoute token={isAuth}>
            <CreateNotesForm />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/call_recordings/create-notes"
        element={
          <PrivateRoute token={isAuth}>
            <CollaborateCreateNotes />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/call_recordings/folders-details"
        element={
          <PrivateRoute token={isAuth}>
            <FolderView />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/call_recordings/recent-details"
        element={
          <PrivateRoute token={isAuth}>
            <RecentView />
          </PrivateRoute>
        }
      /> */}
      <Route
        exact="true"
        path="/call_recordings/transcript"
        element={
          <PrivateRoute token={isAuth}>
            <TranscriptDetail />
          </PrivateRoute>
        }
      />
      {/* <Route
          exact="true"
          path="/collaborate/note/:id"
          element={
            <PrivateRoute token={isAuth}>
              <CreateNotesForm />
            </PrivateRoute>
          }
        />
        <Route
          exact="true"
          path="/collaborate/folder/:id"
          element={
            <PrivateRoute token={isAuth}>
              <FolderView />
            </PrivateRoute>
          }
        />
        <Route
          exact="true"
          path="/collaborate/sleeve/:folder-id/:sleeve-id"
          element={
            <PrivateRoute token={isAuth}>
              <RecentView />
            </PrivateRoute>
          }
        /> */}
      {/* <Route
        exact="true"
        path="/share"
        element={
          <PrivateRoute token={isAuth}>
            <ShareWithMe />
          </PrivateRoute>
        }
      />{" "} */}
      {/* <Route
        exact="true"
        path="/share/note/:id"
        element={
          <PrivateRoute token={isAuth}>
            <SharedNotes />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/share/folder/:id"
        element={
          <PrivateRoute token={isAuth}>
            <ShareFolderView />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/share/sleeve/:sleeve_id"
        element={
          <PrivateRoute token={isAuth}>
            <ShareRecentView />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/share/content/:content_id"
        element={
          <PrivateRoute token={isAuth}>
            <SharedContent />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/share/transcript"
        element={
          <PrivateRoute token={isAuth}>
            <TranscriptDetails />
          </PrivateRoute>
        }
      /> */}
      <Route
        exact="true"
        path="/projects-details-message"
        element={
          <PrivateRoute token={isAuth}>
            <ProjectsDetailsMessage />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/projects-details-expert"
        element={
          <PrivateRoute token={isAuth}>
            <ProjectsDetailsExpert />
          </PrivateRoute>
        }
      />
      {/* <Route
        exact="true"
        path="/conversations"
        element={
          <PrivateRoute token={isAuth}>
            <Conversations />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/expert-call"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertCell />
          </PrivateRoute>
        }
      /> */}
      <Route
        exact="true"
        path="/usermangement/users"
        element={
          <PrivateRoute token={isAuth}>
            <UserMangement />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/usermangement/roles"
        element={
          <PrivateRoute token={isAuth}>
            <RolesManagement />
          </PrivateRoute>
        }
      />
      {/* <Route
        exact="true"
        path="/usermangement/update-roles"
        element={
          <PrivateRoute token={isAuth}>
            <UpdateRoleManagement />
          </PrivateRoute>
        }
      /> */}
      <Route
        exact="true"
        path="/usermangement/addroles"
        element={
          <PrivateRoute token={isAuth}>
            <AddRoleManagement />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/help-support"
        element={
          <PrivateRoute token={isAuth}>
            <HelpSupport />
          </PrivateRoute>
        }
      />
      {/* <Route
        exact="true"
        path="/news"
        element={
          <PrivateRoute token={isAuth}>
            <News />
          </PrivateRoute>
        }
      /> */}
      <Route
        exact="true"
        path="/notification"
        element={
          <PrivateRoute token={isAuth}>
            <NotificationPage />
          </PrivateRoute>
        }
      />
      <Route exact="true" path="/otp-verify" element={<OtpVerify />}></Route>
      <Route
        exact="true"
        path="/profile"
        element={
          <PrivateRoute token={isAuth}>
            <UserProfile />
          </PrivateRoute>
        }
      />
      {/* <Route
        exact="true"
        path="/starred"
        element={
          <PrivateRoute token={isAuth}>
            <Starred />
          </PrivateRoute>
        }
      /> */}
      <Route
        exact="true"
        path="/change-password"
        element={
          <PrivateRoute token={isAuth}>
            <ChangePassword />
          </PrivateRoute>
        }
      />
      {/* <Route
        exact="true"
        path="/expert-login"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertLogin />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/expert-register"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertRegister />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/landing-register"
        element={
          <PrivateRoute token={isAuth}>
            <LandingRegister />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/expert-dashboard"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertDashboard />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/expert-callhistory"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertCallHistory />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/categories"
        element={
          <PrivateRoute token={isAuth}>
            <EditCategories />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/expert-projectdetails"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertProjectDetails />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/expert-payment"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertPayment />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/project/expert-profile"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertSubmittedProfile />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/chat-gpt"
        element={
          <PrivateRoute token={isAuth}>
            <ChatGpt />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/expert-compliance"
        element={
          <PrivateRoute token={isAuth}>
            <ExpertCompliance />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        exact="true"
        path="/register-step"
        element={<RegisterStepOne />}
      ></Route> */}
      <Route exact="true" path="/demo" element={<GetDemo />} />
      {/* <Route exact="true" path="/fc" element={<Fullcalendar />} /> */}
      <Route
        exact="true"
        path="/verify-otp"
        element={<OtpVerifyDemo />}
      ></Route>
      <Route exact="true" path="/calendly" element={<Calendly />}></Route>
      <Route exact="true" path="/expire" element={<ExpiredPage />}></Route>
      <Route exact="true" path="/login" element={
        <PrivateRouteTwo token={isAuth}>
          <Login />
        </PrivateRouteTwo>
      }></Route>
      <Route exact="true" path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
