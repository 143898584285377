import { Button, Checkbox } from "antd";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import API_PATH from "../Constants/api-path";
import "./Register.css";

const text = <span>Please check this box if want to proceed.</span>;

const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

function DemoForm() {
  const [spinner, setSpinner] = useState(false);
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [userData, setValue] = useState({
    fullName: "",
    companyName: "",
    email: "",
    contact_code: "",
    contact_number: "",
    country: "",
    companyInd: "",
    // registrationType : "client_portal"
    registrationType: "client"
  });
  let history = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [warningVisible, setWarningVisible] = useState(false);

  const [toastClosed, setToastClosed] = useState(false);
  const [toastClosedInvalid, setToastClosedInvalid] = useState(false);

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setValue({ ...userData, [key]: value });
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    // const contact_code = country?.dialCode;
    // const contact_number = value?.replace(country?.dialCode, "");

    // const isValidContact = contact_code && contact_number;

    if (selectedCountry) {
      setValue((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        contact_number: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));

      // setContactValid(isValidContact);
    } else {
      console.info("No matching country found for dial code:", country.dialCode);
    }
  };

  const resgisterUser = async (e) => {
    setSpinner(true);
    const { fullName, companyName, email, companyInd } = userData;

    if (
      fullName !== "" &&
      companyName !== "" &&
      email !== "" &&
      companyInd !== "" &&
      phone !== ""
    ) {
      const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!regEx?.test(userData?.email?.toLowerCase())) {
        setSpinner(false);
        return setMessage(
          "The email address you entered is not in a valid format"
        );
      } else {
        setMessage("");
      }

      if (!accepted) {
        setSpinner(false);
        setWarningVisible(true);
        return;
      }

      setSpinner(true);

      userData.email = userData?.email?.toLowerCase();

      const res = await fetch(`${API_PATH.DEMO_POST}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });

      const result = await res.json();

      if (result) {
        if (result?.status === 200) {
          setSpinner(true);
          localStorage.setItem(
            "registered-email",
            userData.email.toLowerCase()
          );
          localStorage.setItem("userData", JSON.stringify(result));
          history("/verify-otp");
          return toast.success("OTP has been sent to your email", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          setSpinner(false);
          if (
            result?.message === "Please use business email." &&
            result?.success === false
          ) {
            setMessage(result?.message);
          } else {
            return toast.error(result?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        }
      }
    } else {
      setSpinner(false);
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setSpinner(false);
  };

  const RememberMe = () => {
    return (
      <div style={{ fontSize: "1rem" }}>
        I agree to
        <a
          className="mr-2"
          href="https://nextyn.com/privacy-policy"
          target="_blank"
          style={{ color: "#523EE8", marginLeft: "5px" }}
        >
          Privacy and Terms
        </a>
      </div>
    );
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Full Name* </label>
              <input
                type="text"
                className="form-control"
                name="fullName"
                onChange={handleInputs}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label> Company Name* </label>
              <input
                type="text"
                className="form-control"
                name="companyName"
                onChange={handleInputs}
                // placeholder="Enter Your Company Name"
                required
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Email* </label>
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={handleInputs}
            // placeholder="Enter Your Business Email"
            required
          />

          {message === "" ? (
            <></>
          ) : (
            <p
              className="text-danger text-left font-weight-light"
              style={{ fontSize: "16px", fontWeight: "400" }}
            >
              {message}
            </p>
          )}
        </div>
        <div className="form-group">
          <label> Company Industry* </label>
          <input
            type="text"
            className="form-control"
            name="companyInd"
            onChange={handleInputs}
            // placeholder="Enter Your Business Email"
            required
          />
        </div>

        <div className="form-group">
          <label> Contact Number* </label>
          <div>
            <PhoneInput
              country={"us"}
              enableSearch={true}
              value={phone}
              onChange={(value, country) => {
                handlePhoneChange(value, country);
              }}
              inputStyle={{
                width: "100%",
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-10 col-md-12">
            <div className="d-flex align-align-items-center text-center">
              <Checkbox
                className="me-2 required-checkbox"
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
                required
              ></Checkbox>
              <RememberMe />
            </div>
            {warningVisible && (
              <p
                className="text-danger"
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                You must accept the privacy and terms to continue
              </p>
            )}
          </div>
        </div>

        <div className="form-group">
          <Button
            className="btn login-btn"
            style={{ textTransform: "capitalize" }}
            onClick={resgisterUser}
            id="Register"
            type="submit"
          // disabled={spinner}
          >
            {spinner ? (
              <>
                <Spinner
                  size="sm"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginRight: "5px",
                  }}
                />
                Submit
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>

        <div className="form-group">
          <p className="text-center">
            <NavLink to="/" className="register-btn px-2">
              Login
            </NavLink>
          </p>
        </div>
      </form>
    </div>
  );
}

export default DemoForm;
