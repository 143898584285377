// privateRoute
import React from "react";
import {Navigate} from "react-router-dom";
import useDecodedToken from "../Utils/useDecodedToken";
// import {isExpired,decodeToken} from "react-jwt";
// // import { connect } from 'react-redux';
// const userData = JSON.parse(localStorage.getItem("userData"));
// const token = userData?.access_token;
const PrivateRoute = ({children, token}) => {
  const decodedToken = useDecodedToken();

  //   let isAuth = JSON.parse(localStorage.getItem("isAuth"));
  //     if(!isAuth){
  //         return <Navigate to="/" replace/>
  //     }
  //     return children;
  if (decodedToken) {
    return children;
  }
  <Navigate to="/" replace />;
};

export default PrivateRoute;
