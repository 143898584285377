import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import "./Dashboard.css";

const ClockRow = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [greeting, setGreeting] = useState("");

  const [clock, setClock] = useState([
    { name: "Melbourne", timezone: "Australia/Melbourne", time: "" },
    { name: "Singapore", timezone: "Asia/Singapore", time: "" },
    { name: "Berlin", timezone: "Europe/Berlin", time: "" },
    { name: "London", timezone: "Europe/London", time: "" },
    { name: "New York", timezone: "America/New_York", time: "" },
    { name: "California", timezone: "America/Los_Angeles", time: "" },
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedClocks = clock?.map((clock) => {
        const currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: clock?.timezone,
        });

        return {
          ...clock,
          time: currentTime,
        };
      });

      setClock(updatedClocks);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [clock?.timezone]);

  useEffect(() => {
    const currentTime = moment().format("HH:mm");
    const currentHour = parseInt(currentTime.split(":")[0], 10);

    if (currentHour >= 0 && currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour >= 12 && currentHour < 16) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  return (
    <>
      <div>
        <div
          // className="bk-div float-start w-100"
          style={{ paddingBottom: "20px", paddingTop: "10px" }}
        >
          <div className="body-expart-div">
            <div className="d-flex justify-content-between ">
              <p className="fs-5 mb-4" style={{ color: "#5746ec" }}>
                {greeting}, {userData?.user?.name?.split(" ")[0]}
              </p>
            </div>
            <div className="d-flex gap-2 clock-wrapper">
              {clock?.map((clocks, index) => (
                <div className="clock-row text-center" key={index}>
                  <div className="clock-row text-center">
                    <div className="card time-clock d-flex justify-content-center align-items-center text-center">
                      <Clock
                        value={clocks?.time}
                        hourMarksLength={10}
                        renderNumbers={true}
                        size={400}
                        timeFormat="24hour"
                        hourFormat="standard"
                        renderHourMarks={true}
                        is24Hour={true}
                        renderMinuteMarks={false}
                      />{" "}
                      <p className="fw-bold mt-2 mb-0">{clocks?.name}</p>
                      <p className="fw-normal mb-0">
                        {clocks?.timezone === "Asia/Singapore"
                          ? "SGT"
                          : moment.tz(clocks?.timezone).format("z")}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <div>
              <Projects />
            </div>
            <div>
              <CallRecordsTanspt />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClockRow;
