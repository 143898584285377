import Chip from "@mui/material/Chip";
import { Checkbox, Input } from "antd";
import axios from "axios";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import API_PATH from "../Constants/api-path";

const { Search } = Input;

const Country = [
  "India",
  "United States of America",
  "United Kingdom",
  "Canada",
  "Germany",
  "France",
  "Australia",
  "Spain",
  "Singapore",
  "Indonesia",
  "Netherland",
  "UAE",
  "Sweden",
  "Italy",
  "Belgium",
  "Thailand",
  "Switzerland",
  "Hongkong",
  "Malaysia",
  "Portugal",
];

const Companies = [
  "Amazon",
  "Adyen",
  "3M",
  "Accenture",
  "Adidas",
  "Bank of America",
  "Airbus",
  "Akzonobel",
  "Airwallex",
  "Babylon Health",
  "Air France-KLM",
  "Airmeet",
  "Alvin.ai",
  "American Express",
  "Angel India Pvt Ltd",
  "Arab National Bank",
  "Aramco",
  "ArcelorMittal Nippon Steel India Limited",
  "Bajaj Finance",
  "Bank of Singapore",
];

const Industries = [
  "Automobiles",
  "Alternative Energy Sources",
  "Automotive",
  "Asset Management",
  "Aerospace",
  "Defense",
  "Aircraft Leasing",
  "Alcoholic Beverage Distribution",
  "Alternative Fuels",
  "Analytics and Reporting software, data and Analytics Services",
  "Aviation",
  "Ecommerce",
  "Agriculture Services and Processing",
  "Aircraft Manufacturing and Engineering Services",
  "Architecture Services",
  "Artificial Intelligence",
  "Investment Services",
  "Air Freight Transportations",
  "Apparel Retailers",
];

function TopSelectOption({
  setCompany,
  dcompany,
  dpcompany,
  setpCompany,
  setIndustry,
  dindustry,
  setExpertise,
  dexpertise,
  setCountry,
  dcountry,
}) {
  const [open, setOpen] = useState(false);
  const catMenu = useRef(null);
  const [openSlide, setopenSlide] = useState(false);
  const [allCountry, setAllCountry] = useState([]);
  const [allTags, setAllTags] = useState("");
  const [allIndustry, setAllIndustry] = useState("");
  const [allCompany, setAllCompany] = useState("");
  const [allPCommpany, setAllpreviousCompany] = useState("");
  const [company, setFilteredCompany] = useState("");
  const [previousCompany, setFilteredpCompany] = useState("");
  const [industry, setFilteredIndustry] = useState("");
  const [tags, setFilteredExpertise] = useState("");
  const [country, setFilteredCountry] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedComany, setSelectedCompany] = useState([]);
  const [selectedpCompany, setSelectedPCompany] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedExpertise, setSelectedExpertise] = useState([]);
  let history = useNavigate();
  useEffect(() => {
    getExpertsOptions();
    // Function for click event
    function handleOutsideClick(event) {
      if (catMenu.current && !catMenu.current.contains(event.target)) {
        setopenSlide(true);
      }
    }
    document.addEventListener("click", handleOutsideClick);
    if (dcountry.split(",")[0] !== "") setSelectedItem(dcountry.split(","));
    if (dcompany.split(",")[0] !== "") setSelectedCompany(dcompany.split(","));
    if (dpcompany.split(",")[0] !== "")
      setSelectedPCompany(dpcompany.split(","));
    if (dindustry.split(",")[0] !== "")
      setSelectedIndustry(dindustry.split(","));
    if (dexpertise.split(",")[0] !== "")
      setSelectedExpertise(dexpertise.split(","));
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [
    catMenu,
    country,
    company,
    previousCompany,
    tags,
    industry,
    dcountry,
    dcompany,
    dpcompany,
    dexpertise,
    dindustry,
  ]);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };

  const getExpertsOptions = () => {
    axios
      .get(`${API_PATH.EXPERT_OPTION}`, {
        headers,
        params: {
          country,
          company,
          pcompany: previousCompany,
          tags,
          industry,
        },
      })
      .then((res) => {
        setAllCountry(
          res.data.country.filter((item) =>
            item.toLowerCase().includes(country.toLowerCase())
          )
        );

        setAllCompany(
          res.data.company.filter((item) =>
            item.toLowerCase().includes(company.toLowerCase())
          )
        );

        setAllpreviousCompany(
          res.data.previousCompany.filter((item) =>
            item.toLowerCase().includes(previousCompany.toLowerCase())
          )
        );

        setAllIndustry(
          res.data.industry.filter((item) =>
            item.toLowerCase().includes(industry.toLowerCase())
          )
        );
        // setAllCompany(Companies);

        setAllTags(
          res.data.tags.filter((item) =>
            item.toLowerCase().includes(tags.toLowerCase())
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [search, setSearch] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [serachPCompany, setSearchPCompany] = useState("");
  const [searchIndustry, setSearchIndustry] = useState("");
  const [searchExpertise, setSearchExpertise] = useState("");
  let queryParams = queryString.parse(window.location.search);
  // const handleClearSearch = (item) => {
  //   setSearch("");
  // };

  const Replacepagechange = (
    ctPage,
    country,
    company,
    previousCompany,
    industry,
    tags,
    searchRgx
  ) => {
    history({
      pathname: `/find-expert`,
      search: `?country=${country}&company=${company}&previousCompany=${previousCompany}&industry=${industry}&expertise=${tags}&search=${searchRgx}&ctPage=${btoa(
        ctPage
      )}`,
    });
  };

  function handleItemClick(item) {
    let countryOption = [...selectedItem, item];
    setSelectedItem(countryOption);
    setCountry(countryOption.join(","));
    Replacepagechange(
      1,
      countryOption.join(","),
      queryParams.company,
      queryParams.previousCompany,
      queryParams.industry,
      queryParams.expertise,
      queryParams.search
    );

    setSearch("");
  }

  // const filteredOptions = OPTIONS?.filter((o) => !selectedItem.includes(o));

  const handleDelete = (id) => {
    // setSelectedItem([]);
    let countryOption = selectedItem.filter((item, i) => i !== id);
    setSelectedItem(countryOption);
    setCountry(countryOption.join(","));
    Replacepagechange(
      1,
      countryOption.join(","),
      queryParams.company,
      queryParams.previousCompany,
      queryParams.industry,
      queryParams.expertise,
      queryParams.search
    );
  };

  function handleItemCompany(item) {
    let companyOption = [...selectedComany, item];
    setSelectedCompany(companyOption);
    setCompany(companyOption.join(","));
    Replacepagechange(
      1,
      queryParams.country,
      companyOption.join(","),
      queryParams.previousCompany,
      queryParams.industry,
      queryParams.expertise,
      queryParams.search
    );
    setSearchCompany("");
  }

  const handleDeleteCompany = (id) => {
    // setSelectedCompany([]);
    let companyOption = selectedComany.filter((item, i) => i !== id);
    setSelectedCompany(companyOption);
    setCompany(companyOption.join(","));
    Replacepagechange(
      1,
      queryParams.country,
      companyOption.join(","),
      queryParams.previousCompany,
      queryParams.industry,
      queryParams.expertise,
      queryParams.search
    );
  };

  function handleItemPCompany(item) {
    let pcompanyOption = [...selectedpCompany, item];
    setSelectedPCompany(pcompanyOption);
    setpCompany(pcompanyOption.join(","));
    Replacepagechange(
      1,
      queryParams.country,
      queryParams.company,
      pcompanyOption.join(","),
      queryParams.industry,
      queryParams.expertise,
      queryParams.search
    );
    setSearchPCompany("");
  }

  const handleDeletepCompany = (id) => {
    // setSelectedCompany([]);
    let pcompanyOption = selectedpCompany.filter((item, i) => i !== id);
    setSelectedPCompany(pcompanyOption);
    setpCompany(pcompanyOption.join(","));
    Replacepagechange(
      1,
      queryParams.country,
      queryParams.company,
      pcompanyOption.join(","),
      queryParams.industry,
      queryParams.expertise,
      queryParams.search
    );
  };

  const handleItemIndustry = (item) => {
    let industryOption = [...selectedIndustry, item];
    setSelectedIndustry(industryOption);
    setIndustry(industryOption.join(","));
    Replacepagechange(
      1,
      queryParams.country,
      queryParams.company,
      queryParams.previousCompany,
      industryOption.join(","),
      queryParams.expertise,
      queryParams.search
    );
    setSearchIndustry("");
  };

  const handleDeleteIndustry = (id) => {
    // setSelectedIndustry([]);
    let industryOption = selectedIndustry.filter((item, i) => i !== id);
    setSelectedIndustry(industryOption);
    setIndustry(industryOption.join(","));
    Replacepagechange(
      1,
      queryParams.country,
      queryParams.company,
      queryParams.previousCompany,
      industryOption.join(","),
      queryParams.expertise,
      queryParams.search
    );
  };

  const handleItemExpertise = (item) => {
    let expertiseOption = [...selectedExpertise, item];
    setSelectedExpertise(expertiseOption);
    setExpertise(expertiseOption.join(","));
    Replacepagechange(
      1,
      queryParams.country,
      queryParams.company,
      queryParams.previousCompany,
      queryParams.industry,
      expertiseOption.join(","),
      queryParams.search
    );
    setSearchExpertise("");
  };

  const handleDeleteExpertise = (id) => {
    // setSelectedExpertise([]);
    let expertiseOption = selectedExpertise.filter((item, i) => i !== id);
    setSelectedExpertise(expertiseOption);
    setExpertise(expertiseOption.join(","));
    Replacepagechange(
      1,
      queryParams.country,
      queryParams.company,
      queryParams.previousCompany,
      queryParams.industry,
      expertiseOption.join(","),
      queryParams.search
    );
  };

  function capitalizeFirstLetter(string) {
    return string.toLowerCase();
    // return newString.charAt(0).toUpperCase() + newString.slice(1);
  }

  return (
    <>
      <div className="select-option customRes" ref={catMenu}>
        <div className="row flex-column flex-md-row">
          <div className="col">
            <div className="location">
              {/* <i class="fa fa-user icon"></i> */}

              <input
                className="card-dropdown dropdown-toggle"
                id="location"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                // placeholder={value.name}
                style={{ height: "2.55rem" }}
                type="search"
                onChange={(event) => {
                  setFilteredCountry(event.currentTarget.value);
                  setSearch(event.target.value);
                }}
                onClick={() => {
                  setOpen(true);
                }}
                placeholder="Location"
                autoComplete="off"
                value={search}
              />
              {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> */}

              <div
                style={{ backgroundColor: "white" }}
                className="dropdown-list dropdown-menu"
                aria-labelledby="location"
              >
                {open ? (
                  allCountry.length ? (
                    allCountry
                      .filter((item) => !selectedItem?.includes(item))

                      ?.map((item, index) => {
                        return (
                          <>
                            {index < 20 ? (
                              <li
                                key={item}
                                className="dropdown-item"
                                outside={setopenSlide}
                                checkClick={openSlide}
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleItemClick(item);
                                  // handleClearSearch(item);
                                  setOpen(false);
                                }}
                              >
                                <Checkbox className="">
                                  {capitalizeFirstLetter(item)}
                                </Checkbox>
                              </li>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })
                  ) : (
                    <div className="text-center">No data found</div>
                  )
                ) : (
                  <></>
                )}
                {/* <p>Selected item: {selectedItem ? selectedItem : "None"}</p> */}

                {/* <div className="px-2 py-3">
                  <div className="d-flex gap-2 justify-content-between">
                    <button
                      className="btn btn-secondary btnCancel min-100 w-auto h-auto"
                      style={{ border: "none" }}
                      onClick={clearInput}
                    >
                      {" "}
                      Clear
                    </button>
                    <button
                      className="btn btn-primary btnBlue min-100 w-auto h-auto"
                      style={{ backgroundColor: "#5746ec", border: "none" }}
                    >
                      Apply
                    </button>
                  </div>
                </div> */}
              </div>

              {/* </ul> */}
            </div>
          </div>

          <div className="inputs-search col">
            <div className="location">
              {/* <i class="fa fa-user icon"></i> */}

              <input
                className="card-dropdown dropdown-toggle"
                id="location"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                // placeholder={value.name}
                style={{ height: "2.55rem" }}
                type="search"
                onChange={(event) => {
                  setFilteredCompany(event.currentTarget.value);
                  setSearchCompany(event.target.value);
                }}
                onClick={() => {
                  setOpen(true);
                }}
                placeholder="Current Company"
                autoComplete="off"
                value={searchCompany}
              />
              {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> */}

              <div
                style={{ backgroundColor: "white" }}
                className="dropdown-list dropdown-menu"
                aria-labelledby="location"
              >
                {open ? (
                  allCompany?.length ? (
                    allCompany
                      ?.filter((item) => !selectedComany.includes(item))
                      ?.map((item, index) => {
                        return (
                          <>
                            {index < 20 ? (
                              <li
                                key={item}
                                className="dropdown-item"
                                // value={allCompany}
                                outside={setopenSlide}
                                checkClick={openSlide}
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleItemCompany(item);
                                  setOpen(false);
                                }}
                              >
                                <Checkbox>
                                  {capitalizeFirstLetter(item)}
                                </Checkbox>
                              </li>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })
                  ) : (
                    <div className="text-center">No data found</div>
                  )
                ) : (
                  <></>
                )}
              </div>
              {/* <p>Selected item: {selectedItem ? selectedItem : "None"}</p> */}

              {/* </ul> */}
            </div>
          </div>

          <div className="inputs-search col">
            <div className="location">
              {/* <i class="fa fa-user icon"></i> */}

              <input
                className="card-dropdown dropdown-toggle"
                id="location"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                // placeholder={value.name}
                style={{ height: "2.55rem" }}
                type="search"
                onChange={(event) => {
                  setFilteredpCompany(event.currentTarget.value);
                  setSearchPCompany(event.target.value);
                }}
                onClick={() => {
                  setOpen(true);
                }}
                placeholder="Past Company"
                autoComplete="off"
                value={serachPCompany}
              />
              {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> */}

              <div
                style={{ backgroundColor: "white" }}
                className="dropdown-list dropdown-menu"
                aria-labelledby="location"
              >
                {open ? (
                  allPCommpany?.length ? (
                    allPCommpany
                      ?.filter((item) => !selectedpCompany.includes(item))
                      ?.map((item, index) => {
                        return (
                          <>
                            {index < 20 ? (
                              <li
                                key={item}
                                className="dropdown-item"
                                // value={allPCommpany}
                                outside={setopenSlide}
                                checkClick={openSlide}
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleItemPCompany(item);
                                  setOpen(false);
                                }}
                              >
                                <Checkbox>
                                  {capitalizeFirstLetter(item)}
                                </Checkbox>
                              </li>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })
                  ) : (
                    <div className="text-center">No data found</div>
                  )
                ) : (
                  <></>
                )}
              </div>
              {/* <p>Selected item: {selectedItem ? selectedItem : "None"}</p> */}

              {/* </ul> */}
            </div>
          </div>

          <div className="inputs-search col">
            <div className="location">
              {/* <i class="fa fa-user icon"></i> */}

              <input
                className="card-dropdown dropdown-toggle"
                id="location"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                // placeholder={value.name}
                style={{ height: "2.55rem" }}
                type="search"
                onChange={(event) => {
                  setFilteredIndustry(event.currentTarget.value);
                  setSearchIndustry(event.target.value);
                }}
                onClick={() => {
                  setOpen(true);
                }}
                placeholder="Industry"
                autoComplete="off"
                value={searchIndustry}
              />
              {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> */}

              <div
                style={{ backgroundColor: "white" }}
                className="dropdown-list dropdown-menu"
                aria-labelledby="location"
              >
                {open ? (
                  allIndustry?.length ? (
                    allIndustry
                      ?.filter((item) => !selectedIndustry?.includes(item))
                      ?.map((item) => (
                        <li
                          key={item}
                          className=" dropdown-item"
                          outside={setopenSlide}
                          checkClick={openSlide}
                          onClick={(event) => {
                            event.preventDefault();
                            handleItemIndustry(item);
                            setOpen(false);
                          }}
                        >
                          <Checkbox>{item}</Checkbox>
                        </li>
                      ))
                  ) : (
                    <div className="text-center">No data found</div>
                  )
                ) : (
                  <> </>
                )}
              </div>
              {/* </ul> */}
            </div>
          </div>

          <div className="inputs-search col">
            <div className="location">
              {/* <i class="fa fa-user icon"></i> */}

              <input
                className="card-dropdown dropdown-toggle"
                id="location"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                // placeholder={value.name}
                style={{ height: "2.55rem" }}
                type="search"
                onChange={(event) => {
                  setFilteredExpertise(event.currentTarget.value);
                  setSearchExpertise(event.target.value);
                }}
                onClick={() => {
                  setOpen(true);
                }}
                placeholder="Expertise"
                autoComplete="off"
                value={searchExpertise}
              />
              {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> */}

              <div
                style={{ backgroundColor: "white" }}
                className="dropdown-list dropdown-menu"
                aria-labelledby="location"
              >
                {open ? (
                  allTags?.length ? (
                    allTags
                      ?.filter((item) => !selectedExpertise?.includes(item))
                      ?.map((item, index) => {
                        return (
                          <>
                            {index < 20 ? (
                              <li
                                key={item}
                                className=" dropdown-item"
                                outside={setopenSlide}
                                checkClick={openSlide}
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleItemExpertise(item);
                                  setOpen(false);
                                }}
                              >
                                <Checkbox>{item}</Checkbox>
                              </li>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })
                  ) : (
                    <div className="text-center">No data found</div>
                  )
                ) : (
                  <></>
                )}
              </div>
              {/* </ul> */}
            </div>
          </div>

          {/* <div className="col">
            <div className="clear-card" style={{ width: "10vw" }}>
              <ClearRefinements className="textColor" />
            </div>
          </div>
            */}
        </div>

        <div className="mt-3">
          {selectedItem != null && selectedItem.length > 0 ? (
            <div className="d-flex flex-wrap align-content-around align-items-center me-2">
              <>
                {selectedItem?.map((selectedItems, id) => (
                  <Chip
                    className="me-1 locationSpeed"
                    key={id}
                    label={
                      selectedItems
                        ? capitalizeFirstLetter(selectedItems)
                        : null
                    }
                    onDelete={() => handleDelete(id)}
                  />
                ))}
              </>
            </div>
          ) : (
            <></>
          )}

          {selectedComany != null && selectedComany.length > 0 ? (
            <div className="d-flex flex-wrap align-content-around align-items-center mt-1 mr-2">
              <>
                {selectedComany?.map((selectedComanys, id) => (
                  <Chip
                    key={id}
                    className="me-1"
                    label={selectedComanys ? selectedComanys : null}
                    onDelete={() => handleDeleteCompany(id)}
                  />
                ))}
              </>
            </div>
          ) : (
            <></>
          )}

          {selectedpCompany != null && selectedpCompany.length > 0 ? (
            <div className="d-flex flex-wrap align-content-around align-items-center mt-1 mr-2">
              <>
                {selectedpCompany?.map((selectedpComanys, id) => (
                  <Chip
                    key={id}
                    className="me-1"
                    label={selectedpComanys ? selectedpComanys : null}
                    onDelete={() => handleDeletepCompany(id)}
                  />
                ))}
              </>
            </div>
          ) : (
            <></>
          )}

          {selectedIndustry != null && selectedIndustry.length > 0 ? (
            <div className="d-flex flex-wrap align-content-around align-items-center mt-1 mr-2">
              <>
                {selectedIndustry?.map((selectedIndustrys, id) => (
                  <Chip
                    className="me-1"
                    key={id}
                    label={selectedIndustrys ? selectedIndustrys : null}
                    onDelete={() => handleDeleteIndustry(id)}
                  />
                ))}
              </>
            </div>
          ) : (
            <></>
          )}

          {selectedExpertise != null && selectedExpertise.length > 0 ? (
            <div className="d-flex flex-wrap align-content-around align-items-center mt-1 mr-2">
              <>
                {selectedExpertise?.map((selectedExpertises, id) => (
                  <Chip
                    className="me-1"
                    key={id}
                    label={selectedExpertises ? selectedExpertises : null}
                    onDelete={() => handleDeleteExpertise(id)}
                  />
                ))}
              </>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
export default TopSelectOption;
