import { Button, Form, Modal, Select } from 'antd';
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import { countryOptions } from '../Utils/Util';
import API_URL from '../Constants/api-path';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

const UserModal = ({ showCreateModal, setCreateModal, getUsers = () => { }, selectedUser = {}, setSelectedUser = () => { }, type = "create" }) => {
    const [form] = Form.useForm()
    const [message, setMessage] = useState("");
    const [roleList, setRoleList] = useState([]);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [spinner, setSpinner] = useState(false);

    const [addUser, setAddUser] = useState({
        firstNmae: "",
        email: "",
        contact_code: "",
        contact_number: "",
        country: "",
        password: "",
        role_id: "",
        user_id: "",
        registrationType: "client"
    });
    const [phone, setPhone] = useState("");
    const handleInputs = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        setAddUser({ ...addUser, [key]: value });
    };
    const handlePhoneChange = (value, country) => {
        setPhone(value);

        const selectedCountry = countryOptions?.find(
            (option) => option?.code === `+${country?.dialCode}`
        );

        if (selectedCountry) {
            setAddUser((prevStep3) => ({
                ...prevStep3,
                contact_code: country?.dialCode,
                contact_number: value?.replace(country?.dialCode, ""),
                country: selectedCountry?.name,
            }));
        } else {
            console.info("No matching country found for dial code:", country.dialCode);
        }
    };
    const headers = React.useMemo(() => ({
        "x-access-token": userData.access_token,
    }), [userData.access_token])

    const getUserList = React.useCallback(async () => {
        try {
            const res = await axios.get(
                `${API_URL.ROLE_MANAGEMENT}?company_id=${userData?.user?.company_id?._id}`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const response = res.data;
            setRoleList(response.data);
        } catch (error) {
            console.error(error);
        }
    }, [headers, userData?.user?.company_id?._id]);

    const handleSubmit = async () => {
        const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regEx.test(addUser?.email?.toLowerCase())) {
            form.setFields([
                {
                    name: "email",
                    errors: [`Email is invalid.`],
                },
            ]);
        }
        setSpinner(true);
        addUser.email = addUser?.email?.toLowerCase();
        fetch(`${API_URL.USER_MANAGEMENT_POST}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                firstNmae: addUser?.firstNmae,
                email: addUser?.email,
                contact_code: addUser?.contact_code,
                contact_number: addUser?.contact_number,
                country: addUser?.country,
                password: addUser?.password,
                role_id: addUser?.role_id,
                registrationType: "client",
                company_id: userData?.user?.company_id?._id
            }),
        })
            .then((res) => {
                getUsers();
                setSpinner(false);
                setCreateModal(false)
                handleClose()
                if (res.status === 200) {
                    toast.success("User added successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            })
            .catch((error) => {
                setCreateModal(false)
                console.error(error);
            });
    };

    const updateUserModal = async () => {
        try {
            const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regEx.test(addUser?.email?.toLowerCase())) {
                form.setFields([
                    {
                        name: "email",
                        errors: [`Email is invalid.`],
                    },
                ]);
            } else {
                setMessage("");
                let email = addUser?.email?.split("@");
                if (
                    email[1] === "gmail.com" ||
                    email[1] === "outlook.com" ||
                    email[1] === "yahoo.com" ||
                    email[1] === "inbox.com" ||
                    email[1] === "icloud.com" ||
                    email[1] === "mail.com"
                ) {
                    form.setFields([
                        {
                            name: "email",
                            errors: [`Please Provide Valid Email.`],
                        },
                    ]);
                    // return setMessage("Please Provide Valid Email");
                } else {
                    setMessage("");
                }
                //return
            }
            setSpinner(true);

            const res = await fetch(
                `${API_URL.USER_MANAGEMENT_UPDATE}/${addUser?.user_id}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        firstNmae: addUser?.firstNmae,
                        email: addUser?.email,
                        contact_number: addUser?.contact_number,
                        contact_code: addUser?.contact_code,
                        country: addUser?.country,
                        role_id: addUser?.role_id,
                        name: addUser?.firstNmae,
                    }),
                }
            );

            if (res.status === 200) {
                setSpinner(false);
                getUsers();
                handleClose()
                return toast.success("User updated successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            } else {
                return toast.error("something went wrong server error", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            setSpinner(false);
            return toast.error("something went wrong server error", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    };

    const callOnce = React.useRef(false)

    React.useEffect(() => {
        if (showCreateModal && !callOnce.current) {
            callOnce.current = true
            getUserList();
        }
    }, [getUserList, showCreateModal]);

    const callOnceUpdate = React.useRef(false)
    React.useEffect(() => {
        if (type === "update" && selectedUser?._id && !callOnceUpdate.current) {
            callOnceUpdate.current = true
            setAddUser({
                firstNmae: selectedUser?.name,
                email: selectedUser?.email,
                contact_code: selectedUser?.contact_code,
                contact_number: selectedUser?.contact_number,
                country: selectedUser?.country,
                password: selectedUser?.name,
                role_id: selectedUser?.role_id ? selectedUser?.role_id?._id : "",
                registrationType: "client",
                user_id: selectedUser?._id
            })
            form.setFieldsValue({
                contact_number: `${selectedUser?.contact_code}${selectedUser?.contact_number}`,
                email: selectedUser?.email,
                first_name: selectedUser?.name,
                role_id: selectedUser?.role_id ? selectedUser?.role_id?._id : ""
            })
        }
    }, [form, getUserList, selectedUser, showCreateModal, type]);
    const handleClose = () => {
        setCreateModal(false)
        callOnceUpdate.current = false
        callOnce.current = false
        form.resetFields()
        setAddUser({
            firstNmae: "",
            email: "",
            contact_code: "",
            contact_number: "",
            country: "",
            password: "",
            role_id: "",
            user_id: "",
            registrationType: "client"
        })
        setSelectedUser({})
    }
    return (
        <Modal
            title={type === "create" ? "Add User" : "Update User"}
            open={showCreateModal}
            onCancel={() => {
                handleClose()
            }}
            centered
            size={"lg"}
            footer={false}
            width={800}
        >
            <Form form={form} layout="vertical" onFinish={() => {
                if (type === "create") {
                    handleSubmit();
                } else {
                    updateUserModal();
                }
            }}>
                <div>
                    <div className="projects-modal-divs">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <Form.Item label="Name" name={"first_name"} rules={[{
                                        required: true,
                                        message: "Name is required"
                                    }]}

                                    >
                                        <input
                                            type="text"
                                            placeholder='Name'
                                            className="form-control"
                                            name="firstNmae"
                                            onChange={handleInputs}
                                        />
                                    </Form.Item>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <Form.Item label="Email Address" name={"email"} rules={[{
                                        required: true,
                                        message: "Email Address is required"
                                    }]}>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder='Email'
                                            className="form-control"
                                            // placeholder="Enter email address"
                                            onChange={handleInputs}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div>
                                        <Form.Item label="Contact Number" name={"contact_number"} rules={[{
                                            required: true,
                                            message: "Contact Number is required"
                                        }]}>
                                            <PhoneInput
                                                country={"us"}
                                                enableSearch={true}
                                                value={phone}
                                                placeholder='Contact Number'
                                                dropdownStyle={{
                                                    position: "fixed",
                                                }}
                                                onChange={(value, country) => {
                                                    handlePhoneChange(value, country);
                                                }}
                                                inputStyle={{
                                                    width: "100%",
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <Form.Item label="Role" name={"role_id"} rules={[{
                                        required: true,
                                        message: "Role is required"
                                    }]}>
                                        <Select
                                            className="form-select"
                                            placeholder='Select Role'
                                            onChange={(value) => handleInputs({
                                                target: {
                                                    name: "role_id",
                                                    value: value
                                                }
                                            })}
                                            name="role_id"
                                            size='large'
                                        >
                                            {roleList?.map((option, index) => (
                                                <Select.Option value={option._id} key={index}>
                                                    {option.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="d-flex justify-content-end">
                    <Button
                        htmlType="button"
                        className="secondaryBttn btn"
                        onClick={() => {
                            handleClose()
                            setCreateModal(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        htmlType="submit"
                        className="viewButton btn ms-2"
                    >
                        Submit {spinner && <LoadingOutlined />}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default UserModal
