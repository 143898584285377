import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

function ScopeProjectInfomation({ datas }) {
  const [Loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    setLoading(false);
  }, [datas]);

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  const splitArray = (array) => {
    let newarray = array.split(",");
    return newarray;
  };

  if (!Loading) {
    return (
      <>
        <ul className="mt-4 list-unstyled mb-0">
          <li className="mb-3 d-flex align-items-center">
            <span>
              <img className="width20" src="/images/expert-icon1.svg" alt="img" />
            </span>
            <div className="text-ex">
              Start Date {convertDate(datas?.created_at)}
            </div>
          </li>

          {/* <li>
            <figure className="l-icon">
              <img className="width20" src="images/expert-icon2.svg" />
            </figure>
            <div className="text-ex">{datas.call_recorded} Recording</div>
          </li> */}

          {/* <li>
            <figure className="l-icon">
              <img className="width20" src="images/expert-icon3.svg" />
            </figure>
            <div className="text-ex">{datas.call_transcript} Transcription</div>
          </li> */}
          {datas?.country ? (
            <li className="mb-3 d-flex align-items-center">
              <span>
                <img className="width20" src="/images/expert-icon4.svg" alt="img" />
              </span>
              <div className="text-ex">
                {datas?.country?.replace(/,(?=[^\s])/g, ", ")}
              </div>
            </li>
          ) : (
            ""
          )}

          <li className="mb-3 d-flex align-items-center">
            <span >
              <img className="width20" src="/images/company-icon.svg" alt="img" />
            </span>
            {datas?.target_companies?.length > 0 ? (
              <div className="text-ex list-btn3">
                {splitArray(datas?.target_companies)
                  .map((curElem, id) => {
                    return curElem;
                  })
                  .join(", ")}
              </div>
            ) : (
              <span style={{ marginLeft: "10px" }}>No companies found</span>
            )}
          </li>
        </ul>
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }
}
export default ScopeProjectInfomation;
