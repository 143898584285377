import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import RolesMangementTable from "./RolesMangementTable";

const RolesMangement = () => {
  document.title = "Client |  User Management";
  const userData = JSON.parse(localStorage.getItem("userData"));
  const history = useNavigate();
  const [isRoleName, setIsRoleName] = useState("");
  const [isRoleDescrip, setIsRoleDescript] = useState("");
  const [loading, setLoading] = useState(false);

  const [isChecked, setIsChecked] = useState({
    selectAllProject: false,
    selectAllCollaboration: false,
    selectAllShare: false,
    selectAllUserManagement: false,
    expertView: false,
    projectAdd: false,
    projectView: false,
    projectMessage: false,
    projectList: false,
    collaborationView: false,
    collabrationNotesAdd: false,
    collaborationNotesView: false,
    collaborationNotesDelete: false,
    collaborationFolderView: false,
    collaborationFolderAdd: false,
    collaborationFolderDelete: false,
    collaborationFilesAdd: false,
    collaborationFilesDelete: false,
    collaborationFilesView: false,
    conversationView: false,
    newsView: false,
    expertCallView: false,
    userManagementView: false,
    userManagementEdit: false,
    userManagementUpdate: false,
    userManagementDelete: false,
  });

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    isChecked[name] === false
      ? setIsChecked({ ...isChecked, [name]: true })
      : setIsChecked({ ...isChecked, [name]: false });
  };

  const handleGroupSelectAll = (group, checked) => {
    const groupKeys = Object.keys(isChecked).filter((key) => key.startsWith(group));
    const updatedState = { ...isChecked };
    groupKeys.forEach((key) => {
      updatedState[key] = checked;
    });
    setIsChecked(updatedState);
  };

  // const handleInputsParam = (name, obj) => {
  //   isChecked[name] === false
  //     ? (isChecked[name] = true)
  //     : (isChecked[name] = false);
  // };

  // const selectAll = (e) => {
  //   Object.keys(isChecked).forEach((elem) => {
  //     handleInputsParam(elem,obj);
  //   });
  // };

  useEffect(() => { }, []);

  const headers = {
    "x-access-token": userData.access_token,
  };

  const submitRolesForm = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${API_PATH.ROLE_MANAGEMENT_POST}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify({
          name: isRoleName,
          description: isRoleDescrip,
          findExperts: {
            view: isChecked.expertView,
          },
          projects: {
            add: isChecked.projectAdd,
            view: isChecked.projectView,
            message: isChecked.projectMessage,
            expertList: isChecked.projectList,
          },
          collaborate: {
            view: isChecked.collaborationView,
            notesAdd: isChecked.collabrationNotesAdd,
            notesView: isChecked.collaborationNotesView,
            notesDelete: isChecked.collaborationNotesDelete,
            folderView: isChecked.collaborationFolderView,
            foldesAdd: isChecked.collaborationFolderAdd,
            folderDelete: isChecked.collaborationFolderDelete,
            filesAdd: isChecked.collaborationFilesAdd,
            filesDelete: isChecked.collaborationFilesDelete,
            filesView: isChecked.collaborationFilesView,
          },
          conversation: {
            view: isChecked.conversationView,
          },
          expertsCall: {
            view: isChecked.expertCallView,
          },
          news: {
            view: isChecked.newsView,
          },
          userManagement: {
            view: isChecked.userManagementView,
            edit: isChecked.userManagementEdit,
            update: isChecked.userManagementUpdate,
            delete: isChecked.userManagementDelete,
          },
        }),
      });

      const result = await res.json();
      if (result) {
        if (result.status === 200) {
          return toast.success("Role Added Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  // const submitRolesForm = async () => {
  //   try {
  //     const res = await axios.post(
  //       `${API_PATH.ROLE_MANAGEMENT_POST}`,
  //       {
  //         method: "POST",
  //         headers: headers,
  //       },
  //       {
  //         name: isRoleName,
  //         description: isRoleDescrip,
  //         findExperts: {
  //           view: isChecked.expertView,
  //         },
  //         projects: {
  //           add: isChecked.projectAdd,
  //           view: isChecked.projectView,
  //           message: isChecked.projectMessage,
  //           expertList: isChecked.projectList,
  //         },
  //         collaborate: {
  //           view: isChecked.collaborationView,
  //           notesAdd: isChecked.collabrationNotesAdd,
  //           notesView: isChecked.collaborationNotesView,
  //           notesDelete: isChecked.collaborationNotesDelete,
  //           folderView: isChecked.collaborationFolderView,
  //           foldesAdd: isChecked.collaborationFolderAdd,
  //           folderDelete: isChecked.collaborationFolderDelete,
  //           filesAdd: isChecked.collaborationFilesAdd,
  //           filesDelete: isChecked.collaborationFilesDelete,
  //           filesView: isChecked.collaborationFilesView,
  //         },
  //         conversation: {
  //           view: isChecked.conversationView,
  //         },
  //         expertsCall: {
  //           view: isChecked.expertCallView,
  //         },
  //         news: {
  //           view: isChecked.newsView,
  //         },
  //         userManagement: {
  //           view: isChecked.userManagementView,
  //           edit: isChecked.userManagementEdit,
  //           update: isChecked.userManagementUpdate,
  //           delete: isChecked.userManagementDelete,
  //         },
  //       }
  //     );

  //     if (res.status === 200) {
  //       history.push("/find-expert");
  //       return toast.success("Roles Added Successful", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     return toast.error(error.response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages expert-cell-page">
                <div className="d-flex top-ps-seection justify-content-between align-items-center">
                  <h2> Add Role </h2>

                  <NavLink
                    to="/usermangement/addroles"
                    className="btn viewButton"
                  >
                    <BsPlusLg />Add Role
                  </NavLink>
                </div>

                <div className="managment-table">
                  <RolesMangementTable load={loading} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* add user modal */}
      <div
        className="modal fade projects-modal"
        id="addRole"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <form onSubmit={submitRolesForm} method="POST">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ border: "none", marginTop: "-8px" }}
              >
                <div className="modal-title h4" style={{ textAlign: "center" }}>
                  {" "}
                  <h2
                    style={{ lineHeight: "30px" }}
                    className="modal-title text-center mx-auto"
                  >
                    {" "}
                    Add Role
                  </h2>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="projects-modal-divs">
                  <div className="d-flex position-relative align-items-center"></div>
                  {/* <p className="text-center">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>
 */}
                  <div className="row mt-5">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Role *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={isRoleName}
                          onChange={(e) => setIsRoleName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="name"
                          value={isRoleDescrip}
                          onChange={(e) => setIsRoleDescript(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <label>Role Permission *</label>
                    <div className="table-responsive">
                      <table className="table-flush-spacing table">
                        <tbody>
                          {/* <tr>
                            <td className="text-wrap fw-bolder">
                              <span className="me-50"> Find Experts</span>{" "}
                            </td>
                            <td>
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{ paddingLeft: "3.5rem" }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.expertView}
                                  name="expertView"
                                  // value={isChecked.expertView}
                                  onChange={handleInputs}
                                />
                                <label
                                  htmlFor="select-all"
                                  className="form-check-label"
                                >
                                  View
                                </label>
                              </div>
                            </td>
                          </tr> */}
                          <tr>
                            <td className="text-nowrap fw-bolder">Project</td>
                            <td>
                              <div className="d-flex">
                                <div className="form-check me-3 me-lg-5" >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked?.selectAllProject}
                                    onChange={(e) => handleGroupSelectAll('project', e.target.checked)}
                                  />
                                  <label className="form-check-label">Select All</label>
                                </div>
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.projectAdd}
                                    name="projectAdd"
                                    value={isChecked.projectAdd}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Add
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.projectView}
                                    name="projectView"
                                    value={isChecked.projectView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="projectMessage"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.projectMessage}
                                    value={isChecked.projectMessage}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Message
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="projectList"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.projectList}
                                    checked={isChecked.projectList}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Expert List
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-nowrap fw-bolder">
                              Call Recordings
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="form-check me-3 me-lg-5" >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked?.selectAllCollaboration}
                                    onChange={(e) => handleGroupSelectAll('collaboration', e.target.checked)}
                                  />
                                  <label className="form-check-label">Select All</label>
                                </div>
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    name="collaborationView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationView}
                                    checked={isChecked.collaborationView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collabrationNotesAdd"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collabrationNotesAdd}
                                    checked={isChecked.collabrationNotesAdd}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Add Notes
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationNotesView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationNotesView}
                                    checked={isChecked.collaborationNotesView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View Notes
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationNotesDelete"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationNotesDelete}
                                    checked={isChecked.collaborationNotesDelete}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Delete Notes
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFolderView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFolderView}
                                    checked={isChecked.collaborationFolderView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View Folder
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFolderAdd"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFolderAdd}
                                    checked={isChecked.collaborationFolderAdd}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Add Folder
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFolderDelete"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFolderDelete}
                                    checked={
                                      isChecked.collaborationFolderDelete
                                    }
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Delete Folder
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFilesAdd"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFilesAdd}
                                    checked={isChecked.collaborationFilesAdd}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Add Files
                                  </label>
                                </div>

                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFilesDelete"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFilesDelete}
                                    checked={isChecked.collaborationFilesDelete}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Delete Files
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFilesView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFilesView}
                                    checked={isChecked.collaborationFilesView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View Files
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-wrap fw-bolder">
                              <span className="me-50"> Share with me</span>{" "}
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="form-check me-3 me-lg-5" >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked?.selectAllCollaboration}
                                    onChange={(e) => handleGroupSelectAll('collaboration', e.target.checked)}
                                  />
                                  <label className="form-check-label">Select All</label>
                                </div>
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.expertView}
                                    name="expertView"
                                    // value={isChecked.expertView}
                                    onChange={handleInputs}
                                  />
                                  <label
                                    htmlFor="select-all"
                                    className="form-check-label"
                                  >
                                    View
                                  </label>
                                </div>
                              </div>

                            </td>
                          </tr>

                          <tr>
                            <td className="text-nowrap fw-bolder">
                              Conversation
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    name="conversationView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.conversationView}
                                    checked={isChecked.conversationView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-nowrap fw-bolder">News </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    name="newsView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.newsView}
                                    checked={isChecked.newsView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-nowrap fw-bolder">
                              Experts Call
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    name="expertCallView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.expertCallView}
                                    checked={isChecked.expertCallView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="text-nowrap fw-bolder"
                              style={{ color: "##000" }}
                            >
                              User Management
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    name="userManagementView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementView}
                                    checked={isChecked.userManagementView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="userManagementEdit"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementEdit}
                                    checked={isChecked.userManagementEdit}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Edit
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="userManagementUpdate"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementUpdate}
                                    checked={isChecked.userManagementUpdate}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Update
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="userManagementDelete"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementDelete}
                                    checked={isChecked.userManagementDelete}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Delete
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-5">
                      <button
                        // data-bs-dismiss="modal"
                        type="submit"
                        className="btn col-lg-5 mx-auto btnsm"
                      >
                        Add Role
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RolesMangement;
