import { Button, Form, Input, notification, Tooltip } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { email_login, otp_login } from "../Redux/features/auth.slice";
import { ExclamationCircleOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input-rc-17";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import API_URL from "../Constants/api-path";
import { toast } from "react-toastify";

function LoginForm() {
  const [form] = Form.useForm();
  const [visible,] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const InputType = visible ? "text" : "password";
  const { loading, } = useSelector((state) => state.auth)
  const [userData, setValue] = useState({
    password: "",
    email: "",
    registrationType: "client",
    loginType: "" //password or otp
  });

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setValue({ ...userData, [key]: value });
  };

  const dispatch = useDispatch()
  const loginUser = async () => {
    const { email, password } = userData
    localStorage.setItem(
      "ChangePassword",
      JSON.stringify(password)
    );
    dispatch(email_login({ email, password }))
  }

  const something = (event) => {
    if (event.keyCode === 13) {
      // loginUser(event);
      const { email, password } = userData
      localStorage.setItem(
        "ChangePassword",
        JSON.stringify(password)
      );
      dispatch(email_login({ email, password }))
    }
  };

  const sendOTP = async () => {
    try {
      setLoadingEmail(true)
      const response = await fetch(`${API_URL.SENDOTP}/${userData?.email}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          registrationType: "client"
        })
      });
      let login_response = await response.json()
      if (login_response?.status === 200 || login_response?.status === "200") {
        setLoadingEmail(false)
        form.setFieldValue("password", "")
        setValue({
          ...userData,
          password: "",
          loginType: "otp"
        })

        setTimeout(() => {
          return toast.success("OTP Sent Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }, 100);
      } else {
        setLoadingEmail(false)
        toast.error(login_response.message
          , {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
      }
    } catch (error) {
      setLoadingEmail(false)
      notification.error({
        message: error.message,
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  }
  return (
    <>
      <Form form={form} layout="vertical" autoComplete="off" onFinish={() => {
        if (userData?.loginType === "otp") {
          let { email, password } = userData
          dispatch(otp_login({ email, password }))
        } else {
          loginUser()
        }
      }}>
        {userData?.loginType === "" && <Form.Item name="email" label="Email Address" rules={[
          {
            type: "email",
            message: "Please enter a valid email address!",
          },
          {
            required: true,
            message: "Email is required!",
          },
        ]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            type="text"
            name="email"
            value={userData?.email}
            onChange={handleInputs}
            className="form-control"
            autoComplete="nope"
            placeholder="Enter Your Email Address"
            size="large"
          />
        </Form.Item>}
        {userData?.loginType !== "" &&
          <div className="mb-4"> <Tooltip title="Back"><MdOutlineKeyboardBackspace style={{
            cursor: "pointer",
            fontSize: 22,
            marginRight: 4
          }} onClick={() => {
            form.setFieldValue("password", "")
            setValue({
              ...userData,
              password: "",
              registrationType: "client",
              loginType: "" //password or otp
            })
          }} /></Tooltip><span className="fw-bold fs-5">Email -</span> <span className="fs-5"> {userData?.email}</span></div>
        }
        {userData?.loginType === "password" && <><Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            name="password"
            onChange={handleInputs}
            className="password-border"
            id="password-field"
            type={InputType}
            size="large"
            autoComplete="nope"
            // onKeyDown={(e) => something(e)}
            placeholder="Password"
          />
        </Form.Item>
          <NavLink
            className="d-flex justify-content-end"
            to="/forgot-password"
            style={{ textDecoration: "none" }}
          >
            <small style={{ color: "#523EE8" }}>Forgot Password?</small>
          </NavLink></>}
        {userData?.loginType === "otp" && <>
          <Form.Item
            name="password"
            label="OTP"
            rules={[
              {
                required: true,
                message: "Please input your OTP!",
              },
            ]}
          >
            {/* <div
              id="otp"
              className="d-flex justify-content-center align-items-center flex-row"
            > */}
            <OtpInput
              value={userData?.password}
              onChange={(otp) => {
                setValue({
                  ...userData,
                  password: otp
                })
              }}
              numInputs={6}
              inputStyle={{
                width: "100%",
                height: "2.5rem",
                margin: "0 0.5rem",
                fontSize: "20px",
                borderRadius: 4,
                border: "1px solid #5746ec ",
              }}
            />
            {/* </div> */}
          </Form.Item>

        </>}
        {userData?.loginType === "" && <div className="d-flex align-items-center justify-content-center w-100 gap-2">
          <Form.Item shouldUpdate className="flex-1 w-100">
            <Button htmlType="button"
              className="login-btn"
              disabled={userData?.email === "" || (userData?.email && form.getFieldError("email")?.length !== 0) || loadingEmail}
              onClick={() => {
                let errorLen = form.getFieldError("email")?.length
                if (errorLen === 0) {
                  form.setFieldValue("password", "")
                  setValue({
                    ...userData,
                    password: "",
                    loginType: "password"
                  })
                }

              }}>
              Login With Password
            </Button>
          </Form.Item>
          <Form.Item shouldUpdate className="flex-1 w-100">
            <Button htmlType="button"
              className="login-btn m-0"
              disabled={userData?.email === "" || (userData?.email && form.getFieldError("email")?.length !== 0) || loading}
              onClick={() => {
                let errorLen = form.getFieldError("email")?.length
                if (errorLen === 0) {
                  sendOTP()
                }
              }}>
              Login With OTP
            </Button>
          </Form.Item>
        </div>}

        {userData?.loginType !== "" && <div className="form-group">
          <Form.Item shouldUpdate>
            <Button htmlType="submit"
              className="btn login-btn">
              {loading ? (
                <>
                  <Spinner
                    size="sm"
                    style={{
                      width: "1rem",
                      height: "1rem",
                      marginRight: "5px",
                    }}
                  />
                  Login
                </>
              ) : (
                "Login"
              )}
            </Button>
          </Form.Item>
        </div>}
        {userData?.loginType === "otp" && <div className="form-group m-0">
          <p className="text-center m-0">
            <NavLink className="register-btn px-2" onClick={() => {
              sendOTP()
            }}>
              Resend OTP
            </NavLink>
          </p>
        </div>}
      </Form >

      {userData?.loginType === "" && <div className="form-group">
        <p className="text-center">
          <NavLink to="/demo" className="register-btn px-2">
            Request Demo
          </NavLink>
        </p>
      </div>}
    </>
  );
}
export default LoginForm;
