import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import API_PATH from "../Constants/api-path";
import { MdDelete } from "react-icons/md";
import { message } from "antd";

function ScopeTeamsli({ datas = [], id, getClientProjectDetails = () => { } }) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [Loading, setLoading] = useState(true);
  const [intData, setInvtData] = useState("");
  const [handleDeleteExpert, setDeleteExpert] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [datas]);

  // const DeleteModal = (email, name) => {
  //   let emailname = email.split(" ");
  //   if (emailname[0] !== "Invite") {
  //     setDeleteEmail(email);
  //   } else {
  //     setDeleteEmail(name);
  //   }
  //   setModalToggle(true);
  // };

  const convertDate = (date) => {
    if (date !== null && date !== "" && date) {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "--";
    }
  };

  // const handelDelete = (deleteEmail) => {
  //   let emailID = deleteEmail.split("@")[0];
  //   axios
  //     .get(`${API_PATH.REMOVE_INVITES}`, {
  //       // Add the request body here
  //       project_id: id,
  //       invite_team: emailID,
  //     })
  //     .then((res) => {
  //       setModalToggle(false);
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //       if (res.data.data === "Remove Successfully") {
  //         return toast.success("Removed Successfully", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       }

  //       //window.location.reload();
  //     })
  //     .catch((err) => {
  //     });
  // };

  const editName = (email) => {
    if (typeof email !== "string") {
      return "";
    }

    let emailID = email.split("@")[0];
    let [firstName, lastName] = emailID.split(".");
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName
      ? lastName.charAt(0).toUpperCase() + lastName.slice(1)
      : "";
    return `${firstName} ${lastName}`.trim();
  };

  const DeleteInvite = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.PROJECT_INVITE_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: id,
          invite_team: intData,
        }),
      });
      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          setDeleteExpert(false);
          message.success("Invite_team remove successfully!!");
          getClientProjectDetails();
        } else {
          message.error("something went wrong server error");
        }
      }
    } catch (error) {
      setLoading(false);

      message.error("something went wrong server error");
    }
    setLoading(false);
  };

  const removeNextynFromEmail = (email) => {
    if (email && email?.includes("@nextyn.com")) {
      return email?.replace("@nextyn.com", "");
    }
    return email;
  };

  const getInvite = (inviteData) => {
    setInvtData(inviteData);
  };

  if (!Loading) {
    return (
      <>
        <div className="client-teams-ul-li">
          <ul className="list-unstyled">
            {datas?.map((curElem, id) => {
              return (
                <>
                  <li key={id} className="mb-3">
                    <div className="teams-li d-flex justify-content-between align-items-center flex-1">
                      <div>
                        <figure>
                          <div className="avatarImg">
                            <img
                              width="40px"
                              src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${curElem?.email || curElem?.name || "NoName"
                                }`}
                              alt={curElem?.email || curElem?.name || ""}
                            />
                          </div>
                        </figure>
                        <h5 className="m-0" key={id}>
                          {editName(curElem?.email || curElem?.name)}
                          {curElem?.email && (
                            <span className="d-block">{`${curElem?.email}`}</span>
                          )}
                        </h5>
                      </div>
                      {/* <div className="dropdown"> */}
                      <div>
                        <MdDelete
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() => {
                            setDeleteExpert(true);
                            getInvite(removeNextynFromEmail(curElem?.email));
                          }}
                        />
                      </div>
                      {/* </div> */}
                    </div>

                  </li>
                </>
              );
            })}
          </ul>
        </div>
        <Modal
          show={handleDeleteExpert}
          onHide={() => setDeleteExpert(false)}
          backdrop="static"
          keyboard={false}
          centered
          size={"md"}
          scrollable={true}
          className="bt_modal"
          dialogClassName={"bootStrapModal"}
          contentClassName={"bootStrapModalContent"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2 className="mb-0">Are you sure?</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="position-relative">
            <div>
              Do you really want to delete these records? This process cannot be
              undone.
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                className="btn btn-light"
                onClick={() => setDeleteExpert(false)}
              >
                No
              </Button>
              <Button
                className="btn btn-primary ms-2"
                style={{ backgroundColor: "#5746ec", border: "none" }}
                onClick={() => DeleteInvite()}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }
}
export default ScopeTeamsli;
