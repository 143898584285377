import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import "../App.css";
import "../assests/css/custom.css";
import no_result from "../assests/images/No-data-amico.svg";
import API_PATH from "../Constants/api-path";
import AllDataManagement from "./RolesTable";
import { Form, Dropdown, Menu, Table, Tooltip, Space } from "antd";
import { Col, Modal, Row } from "react-bootstrap";
import { MdDelete, MdBorderColor } from "react-icons/md";
import AvatarGroup from "react-avatar-group";
import { MdOutlineClose } from "react-icons/md";

function RolesMangementTable({ load }) {
  const [workData, setworkData] = useState(AllDataManagement);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [spinner, setSpinner] = useState(false);
  const history = useNavigate();
  const [roleList, setRoleList] = useState([]);
  const [isRoleName, setIsRoleName] = useState("");
  const [isRoleDescrip, setIsRoleDescript] = useState("");
  const [isRoleId, setIsRoleId] = useState("");
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showCreateModal, setCreateModal] = useState(false);
  const [form] = Form.useForm();

  const [isUserId, setIsUserId] = useState([]);

  //Role Permissin
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [isChecked, setIsChecked] = useState({
    selectAllProject: false,
    selectAllCollaboration: false,
    selectAllShare: false,
    selectAllUserManagement: false,
    expertView: false,
    projectAdd: false,
    projectView: false,
    projectMessage: false,
    projectList: false,
    collaborationView: false,
    collabrationNotesAdd: false,
    collaborationNotesView: false,
    collaborationNotesDelete: false,
    collaborationTranscriptView: false,
    expertCallView: false,
    userManagementView: false,
    userManagementEdit: false,
    userManagementUpdate: false,
    userManagementDelete: false,
    sharedWithMe: false,
    projectSelectAll: false,
    collaborationSelectAll: false,
    conversationSelectAll: false,
    userManagementSelectAll: false,
  });

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    if (name === "projectSelectAll") {
      setIsChecked((prev) => ({
        ...prev,
        projectAdd: !prev.projectAdd,
        projectView: !prev.projectView,
        projectMessage: !prev.projectMessage,
        projectList: !prev.projectList,
        projectSelectAll: !prev.projectSelectAll,
      }));
    } else if (name === "collaborationSelectAll") {
      setIsChecked((prev) => ({
        ...prev,
        collaborationView: !prev.collaborationView,
        collabrationNotesAdd: !prev.collabrationNotesAdd,
        collaborationNotesView: !prev.collaborationNotesView,
        collaborationNotesDelete: !prev.collaborationNotesDelete,
        collaborationFolderView: !prev.collaborationFolderView,
        collaborationFolderAdd: !prev.collaborationFolderAdd,
        collaborationFolderDelete: !prev.collaborationFolderDelete,
        collaborationFilesAdd: !prev.collaborationFilesAdd,
        collaborationFilesDelete: !prev.collaborationFilesDelete,
        collaborationFilesView: !prev.collaborationFilesView,
        collaborationTranscriptView: !prev.collaborationTranscriptView,
        collaborationSelectAll: !prev.collaborationSelectAll,
      }));
    } else if (name === "conversationSelectAll") {
      setIsChecked((prev) => ({
        ...prev,
        conversationView: !prev.conversationView,
        conversationSelectAll: !prev.conversationSelectAll,
      }));
    } else if (name === "userManagementSelectAll") {
      setIsChecked((prev) => ({
        ...prev,
        userManagementView: !prev.userManagementView,
        userManagementEdit: !prev.userManagementEdit,
        userManagementUpdate: !prev.userManagementUpdate,
        userManagementDelete: !prev.userManagementDelete,
        userManagementSelectAll: !prev.userManagementSelectAll,
      }));
    } else {
      setIsChecked((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));

      // Check if all individual checkboxes are selected
      const allSelected =
        Object.keys(isChecked).filter((key) =>
          key.includes(name) && key !== `${name}SelectAll`
        ).every((key) => isChecked[key]);

      // Update "Select All" checkbox accordingly
      setIsChecked((prev) => ({
        ...prev,
        [`${name}SelectAll`]: allSelected,
      }));
    }
  };


  const getUserList = async () => {
    try {
      const res = await axios.get(
        `${API_PATH.ROLE_MANAGEMENT}?company_id=${userData?.user?.company_id?._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const response = res.data;
      setRoleList(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const updateRoles = async (e) => {
    // e.preventDefault();

    if (isRoleName !== "") {
      setSpinner(true);
      // document.getElementById("editRoleManagement").disabled = true;
      try {
        const res = await fetch(
          `${API_PATH.ROLE_MANAGEMENT_UPDATE}/${isRoleId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({
              name: isRoleName,
              description: isRoleDescrip,
              findExperts: {
                view: isChecked.expertView,
              },
              projects: {
                add: isChecked.projectAdd,
                view: isChecked.projectView,
                message: isChecked.projectMessage,
                expertList: isChecked.projectList,
              },
              collaborate: {
                view: isChecked.collaborationView,
                notesAdd: isChecked.collabrationNotesAdd,
                notesView: isChecked.collaborationNotesView,
                notesDelete: isChecked.collaborationNotesDelete,
                transcriptView: isChecked.collaborationTranscriptView,
              },

              sharedWithMe: {
                view: isChecked?.sharedWithMe,
              },

              expertsCall: {
                view: isChecked.expertCallView,
              },

              userManagement: {
                view: isChecked.userManagementView,
                edit: isChecked.userManagementEdit,
                update: isChecked.userManagementUpdate,
                delete: isChecked.userManagementDelete,
              },
            }),
          }
        );

        const result = await res.json();
        if (result) {
          setSpinner(false);
          // document.getElementById("editRoleManagement").disabled = false;
          if (result.status === 200) {
            // document.getElementById("editRole").click();

            getUserList();
            return toast.success("Role updated successfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const setRoleModal = (curElem) => {
    setIsRoleName(curElem.name);
    setIsRoleDescript(curElem.description);

    setIsChecked({
      userManagementDelete: curElem.userManagement.delete,
      userManagementUpdate: curElem.userManagement.update,
      userManagementEdit: curElem.userManagement.edit,
      userManagementView: curElem.userManagement.view,
      expertCallView: curElem.expertsCall.view,
      collaborationFilesView: curElem.collaborate.filesView,
      collaborationFilesDelete: curElem.collaborate.filesDelete,
      collaborationFilesAdd: curElem.collaborate.filesAdd,
      collaborationFolderDelete: curElem.collaborate.folderDelete,
      collaborationFolderAdd: curElem.collaborate.foldesAdd,
      collaborationTranscriptView: curElem.collaborate.folderView,
      collaborationNotesDelete: curElem.collaborate.notesDelete,
      collaborationNotesView: curElem.collaborate.notesView,
      collabrationNotesAdd: curElem.collaborate.notesAdd,
      collaborationView: curElem.collaborate.view,
      projectList: curElem.projects.expertList,
      projectMessage: curElem.projects.message,
      projectView: curElem.projects.view,
      projectAdd: curElem.projects.add,
      expertView: curElem.findExperts.view,
      sharedWithMe: curElem?.sharedWithMe?.view,
    });

    setIsRoleId(curElem._id);
  };

  const deleteUserModal = async () => {
    // document.getElementById("deletedRoleManagement").disabled = true;
    axios
      .delete(`${API_PATH.ROLE_MANAGEMENT_DELETE}/${isUserId}`, {
        method: "DELETE",
        headers: headers,
      })
      .then((res) => {
        getUserList();
        if (res.data.status === 200) {
          document.getElementById("deletedRoleManagement").disabled = false;
          toast.success("Role deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          // window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getUserId = (curElem) => {
    setIsUserId(curElem._id);
  };

  // const deleteUserModal = () => {
  //   deleteRole(isUserId.filter((item) => item.id !== id));
  // };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  const columns = [
    {
      title: "ID",
      render: (text, record, rowIndex) => rowIndex + 1,
    },
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const addedBy = record?.name;
        if (!addedBy || !addedBy[0]) {
          // Handle the case when 'addedBy' or 'addedBy[0]' is not defined
          return null; // or any appropriate fallback
        }

        return (
          <div>
            <span>{addedBy}</span>
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",

      render: (record) => (
        <>
          {record?.type !== "default" ? (
            <Space size="middle">
              <Tooltip color="#5746EB" title="Edit" placement="top">
                <MdBorderColor
                  onClick={() => {
                    setRoleModal(record);
                    setCreateModal(true);
                  }}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                ></MdBorderColor>
              </Tooltip>

              <Tooltip color="#5746EB" title="Delete" placement="top">
                <MdDelete
                  onClick={() => {
                    getUserId(record);
                    setDeleteModal(true);
                  }}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />
              </Tooltip>
            </Space>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={roleList}
        columns={columns}
        loading={load}
        pagination={false}
        // onChange={handleTableChange}
        scroll={{ x: 800 }}
        size="large"
      />

      <Modal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Are you sure ?</h4>
          </div>
          <div>
            <span style={{ fontSize: "16px" }}>
              {" "}
              Do you really want to delete this role? This process cannot be
              undone.
            </span>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteModal(false)}
            >
              No
            </button>
            <button
              onClick={() => {
                deleteUserModal();
                setDeleteModal(false);
              }}
              className="viewButton btn ms-2"
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"xl"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setCreateModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Edit Role</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="projects-modal-divs">
                {/* <div className="d-flex position-relative align-items-center">
                    <h2 className="text-center mx-auto"> Edit Role </h2>
                  </div> */}
                {/* <p className="text-center">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>  
 */}
                <div className="row mt-5">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Role *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={isRoleName}
                        onChange={(e) => setIsRoleName(e.target.value)}
                        style={{ boxShadow: "none" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="description"
                        value={isRoleDescrip}
                        onChange={(e) => setIsRoleDescript(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <label>Role Permission *</label>
                  <div className="table-responsive">
                    <table className="table-flush-spacing table">
                      <tbody>
                        {/* <tr>
                          <td className="text-wrap fw-bolder">
                            <span className="me-50"> Find Experts</span>{" "}
                          </td>
                          <td>
                            <div
                              className="form-check me-3 me-lg-5"
                              style={{ paddingLeft: "3.5rem" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={isChecked.expertView}
                                name="expertView"
                                value={isChecked.expertView}
                                onChange={handleInputs}
                              />
                              <label
                                htmlFor="select-all"
                                className="form-check-label"
                              >
                                View
                              </label>
                            </div>
                          </td>
                        </tr> */}
                        <tr>
                          <td className="text-nowrap fw-bolder">Project</td>
                          <td>
                            <div className="d-flex">
                              <div className="form-check me-3 me-lg-5" style={{ paddingLeft: "3.5rem" }}>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.projectSelectAll}
                                  name="projectSelectAll"
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">Select All</label>
                              </div>
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{ paddingLeft: "3.5rem" }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  // checked={isprojectAdd.projectAdd}
                                  checked={isChecked.projectAdd}
                                  name="projectAdd"
                                  value={isChecked.projectAdd}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">Add</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.projectView}
                                  name="projectView"
                                  value={isChecked.projectView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">View</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="projectMessage"
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.projectMessage}
                                  value={isChecked.projectMessage}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Message
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="projectList"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.projectList}
                                  checked={isChecked.projectList}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Expert List
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-nowrap fw-bolder">
                            Call Recordings
                          </td>
                          <td>
                            <div className="d-flex">
                              <div className="form-check me-3 me-lg-5" style={{ paddingLeft: "3.5rem" }}>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked?.collaborationSelectAll}
                                  onChange={handleInputs}
                                  name="collaborationSelectAll"
                                />
                                <label className="form-check-label">Select All</label>
                              </div>
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{ paddingLeft: "3.5rem" }}
                              >
                                <input
                                  name="collaborationView"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.collaborationView}
                                  checked={isChecked.collaborationView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">View</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="collabrationNotesAdd"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.collabrationNotesAdd}
                                  checked={isChecked.collabrationNotesAdd}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Add Notes
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="collaborationNotesView"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.collaborationNotesView}
                                  checked={isChecked.collaborationNotesView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  View Notes
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="collaborationNotesDelete"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.collaborationNotesDelete}
                                  checked={isChecked.collaborationNotesDelete}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Delete Notes
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="collaborationTranscriptView"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.collaborationTranscriptView}
                                  checked={
                                    isChecked.collaborationTranscriptView
                                  }
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Recording and Transcript View
                                </label>
                              </div>
                              {/* <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFolderAdd"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFolderAdd}
                                    checked={isChecked.collaborationFolderAdd}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Folder Add
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFolderDelete"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFolderDelete}
                                    checked={
                                      isChecked.collaborationFolderDelete
                                    }
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Folder Delete
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFilesAdd"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFilesAdd}
                                    checked={isChecked.collaborationFilesAdd}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Files Add
                                  </label>
                                </div>

                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFilesDelete"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFilesDelete}
                                    checked={isChecked.collaborationFilesDelete}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Files Delete
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="collaborationFilesView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationFilesView}
                                    checked={isChecked.collaborationFilesView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Files View
                                  </label>
                                </div> */}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-wrap fw-bolder">
                            <span className="me-50"> Share with me</span>{" "}
                          </td>
                          <td>
                            <div
                              className="form-check me-3 me-lg-5"
                              style={{ paddingLeft: "3.5rem" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={isChecked?.sharedWithMe}
                                name="sharedWithMe"
                                value={isChecked.sharedWithMe}
                                onChange={handleInputs}
                              />
                              <label
                                htmlFor="select-all"
                                className="form-check-label"
                              >
                                View
                              </label>
                            </div>
                          </td>
                        </tr>

                        {/* <tr>
                            <td className="text-nowrap fw-bolder">
                              Conversation
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    name="conversationView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.conversationView}
                                    checked={isChecked.conversationView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr> */}
                        {/* <tr>
                            <td className="text-nowrap fw-bolder">News </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    name="newsView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.newsView}
                                    checked={isChecked.newsView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr> */}
                        {/* <tr>
                          <td className="text-nowrap fw-bolder">
                            Experts Call
                          </td>
                          <td>
                            <div className="d-flex">
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{ paddingLeft: "3.5rem" }}
                              >
                                <input
                                  name="expertCallView"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.expertCallView}
                                  checked={isChecked.expertCallView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">View</label>
                              </div>
                            </div>
                          </td>
                        </tr> */}
                        <tr>
                          <td
                            className="text-nowrap fw-bolder"
                            style={{ color: "##000" }}
                          >
                            User Management
                          </td>
                          <td>
                            <div className="d-flex">
                              <div className="form-check me-3 me-lg-5" style={{ paddingLeft: "3.5rem" }}>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.userManagementSelectAll}
                                  name="userManagementSelectAll"
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">Select All</label>
                              </div>
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{ paddingLeft: "3.5rem" }}
                              >
                                <input
                                  name="userManagementView"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.userManagementView}
                                  checked={isChecked.userManagementView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">View</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="userManagementEdit"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.userManagementEdit}
                                  checked={isChecked.userManagementEdit}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">Edit</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="userManagementUpdate"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.userManagementUpdate}
                                  checked={isChecked.userManagementUpdate}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Update
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="userManagementDelete"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.userManagementDelete}
                                  checked={isChecked.userManagementDelete}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Delete
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setCreateModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => {
                setCreateModal(false);
                updateRoles();
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default RolesMangementTable;
