import {Navigate} from "react-router-dom";
import {isExpired, decodeToken} from "react-jwt";
import useDecodedToken from "../Utils/useDecodedToken";

const userData = JSON.parse(localStorage.getItem("userData"));
const token = userData?.access_token;
const PrivateRouteTwo = ({children}) => {
  // const myDecodeToken = decodeToken(token);
  // if (myDecodeToken !== null) {
  //   return <Navigate to="/find-expert" replace />;
  // } else {
  //   return children;
  // }
  const decodedToken = useDecodedToken();

  if (decodedToken) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
};

export default PrivateRouteTwo;
