import { FaRegEnvelope } from "react-icons/fa";
import {
  BsCheck2Circle,
  BsFillCartFill,
  BsFillGeoAltFill,
} from "react-icons/bs";

const AllDataManagement = [
  {
    id: 1,
    // icon:<FaRegEnvelope/>,
    username: "Dhruv Upadhyay",
    email: "Dhruv Upadhyay@gmail.com",
    role: "Team Manager",
    action: "Manage",
    Status: "Online",
    img: "images/team-pic.png",
  },
  {
    id: 2,
    // icon:<BsCheck2Circle/>,
    username: "Dhruv Upadhyay",
    email: "Dhruv Upadhyay@gmail.com",
    role: "Team Manager",
    action: "Manage",
    Status: "Online",
    img: "images/team-pic.png",
  },
  {
    id: 3,
    // icon:<BsFillCartFill/>,
    username: "Dhruv Upadhyay",
    email: "Dhruv Upadhyay@gmail.com",
    role: "Team Manager",
    action: "Manage",
    Status: "Online",
    img: "images/team-pic.png",
  },
  {
    id: 4,
    username: "Dhruv Upadhyay",
    email: "Dhruv Upadhyay@gmail.com",
    role: "Team Manager",
    action: "Manage",
    Status: "Online",
    img: "images/team-pic.png",
  },
];
export default AllDataManagement;
