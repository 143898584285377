import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { NavLink } from "react-router-dom";
import RegisterGif from "../assests/images/Register.gif";
import DemoForm from "./DemoForm";
import nextyn_logo from "../assests/images/NX19.png";
import nextyn_logo2 from "../assests/images/Nextyn_logo.png";

function GetDemo() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });
  document.title = "Client | Register";
  return (
    <>
      <div className="login-page2 register-page">
        <div className="">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col">
              <div className="login-left-part  d-none d-lg-block">
                <NavLink to="/" className="logo">
                  <img src={nextyn_logo} alt="logo" style={{ width: "35%" }} />
                </NavLink>
                <div className="logo-in-div col-lg-7 mx-auto">
                  <h1 className="text-left text-white  " data-aos="fade-down">
                    Access a panel of experts spread across 70+ countries & 30+
                    industries
                  </h1>
                </div>

                <div className="col-lg-7 mt-4 mx-auto">
                  <div className="register-img" data-aos="fade-up">
                    <figure>
                      <img src={RegisterGif} alt="register" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col position-relative d-flex align-items-center justify-content-center"
            // style={{ height: "100vh", overflowY: "scroll" }}
            >
              <div className="comon-fild col-lg-8">
                <NavLink className="logo d-block d-lg-none mb-5 active" to="/">
                  <img src={nextyn_logo2} alt="logo" />
                </NavLink>
                <div className="inside-login">
                  <h4 style={{ textAlign: "left" }}> Register for a Demo</h4>
                  <p>
                    <span style={{ color: "#5746ec" }}>
                      Click, consult & collaborate with experts across the globe
                    </span>{" "}
                  </p>
                  <DemoForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetDemo;
