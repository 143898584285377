import nextyn_logo from "../assests/images/next_l.png";
import nextyn_srt_logo from "../assests/images/nextyn_small_logo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import "../assests/css/ExpertSubmittedProfile.css";

export const generateExpertProfile = async (data) => {
  try {
    const root = document.getElementById("root");
    root.classList.add("generatePDF");
    // let content = document.getElementById("ex_sm_wrppr").innerHTML;
    let cont = document.createElement("div");
    cont.setAttribute("id", "generatedPDF");
    cont.style.position = "fixed";
    cont.style.top = "0";
    cont.style.zIndex = 0;
    cont.style.padding = "30px";
    cont.style.width = "1100px";
    cont.style.height = "auto";
    cont.innerHTML = pdfContent(data);
    document.body.appendChild(cont);
    await html2canvas(cont, {
      scale: 2,
      x: 0,
      y: 0,
      width: 1100,
    }).then((canvas) => {
      document.getElementById("generatedPDF").remove();
      root.classList.remove("generatePDF");
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({ compress: true });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(imgData);
      const footerHeight = 25;
      const imgWidth = pdfWidth;
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
      let pageHeight = pdfHeight - footerHeight;
      let position = 0;

      // create temperary canvas
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = canvas.width;
      tempCanvas.height = (pageHeight * canvas.width) / pdfWidth;
      const tempContext = tempCanvas.getContext("2d");

      while (position < canvas.height) {
        tempContext.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
        tempContext.drawImage(
          canvas,
          0,
          position,
          canvas.width,
          tempCanvas.height,
          0,
          0,
          tempCanvas.width,
          tempCanvas.height
        );

        const croppedImgData = tempCanvas.toDataURL("image/png");

        if (position > 0 && imgHeight > pageHeight) {
          pdf.addPage();
        }

        pdf.addImage(croppedImgData, "PNG", 0, 0, pdfWidth, pageHeight);

        position += tempCanvas.height;

        // Add footer
        pdf.setFontSize(10);
        pdf.setTextColor("#C41C36");
        pdf.text(
          "Nextyn Advisory Private Limited (CIN:U74999MH2018PTC304669)",
          pdfWidth / 2,
          pdfHeight - 15,
          {
            align: "center",
          }
        );
        pdf.setFontSize(8);
        pdf.setTextColor(150, 150, 150);
        pdf.text(
          "Strictly Private and Confidential",
          pdfWidth / 2,
          pdfHeight - 10,
          {
            align: "center",
          }
        );

        pdf.addImage(nextyn_srt_logo, pdfWidth - 20, pdfHeight - 18, 10, 10);
      }
      pdf.save(`profile.pdf`);
      return true;
    });
  } catch (error) {
    console.error("error: ", error);
    return false;
  }
};

const pdfContent = (data) => {
  let currentDesignationHTML =
    data?.expert_experience?.filter((dat) => dat?.current_designation)?.length >
      0
      ? data?.expert_experience
        ?.filter((dat) => dat?.current_designation)
        ?.map((exp) => {
          if (exp?.show_check === "yes") {
            return `<tr><td style="font-weight: 500; width:700px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px;">${exp?.current_designation
              }, ${exp?.current_compny
              }</td><td style="font-weight: 500; width:20%;border-bottom:1px solid #c6c6c6;padding:10px;">${!["", "null", null, undefined, "undefined"]?.includes(
                exp?.current_designation_date?.split("-")?.[0]?.trim()
              )
                ? moment(
                  exp?.current_designation_date?.split("-")?.[0]?.trim(),
                  ["MM, YYYY"]
                )?.format("MMM YYYY")
                : "NA"
              } - ${exp?.till_present === "yes"
                ? "Present"
                : !["", "null", null, undefined, "undefined"]?.includes(
                  exp?.current_designation_date?.split("-")?.[1]?.trim()
                )
                  ? moment(
                    exp?.current_designation_date?.split("-")?.[1]?.trim(),
                    ["MM, YYYY"]
                  )?.format("MMM YYYY")
                  : "NA"
              }</td></tr>`;
          } else {
            return `<tr><td style="width:700px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px;">${exp?.current_designation
              }, ${exp?.current_compny
              }</td><td style="width:20%;border-bottom:1px solid #c6c6c6;padding:10px;">${!["", "null", null, undefined, "undefined"]?.includes(
                exp?.current_designation_date?.split("-")?.[0]?.trim()
              )
                ? moment(
                  exp?.current_designation_date?.split("-")?.[0]?.trim(),
                  ["MM, YYYY"]
                )?.format("MMM YYYY")
                : "NA"
              } - ${exp?.till_present === "yes"
                ? "Present"
                : !["", "null", null, undefined, "undefined"]?.includes(
                  exp?.current_designation_date?.split("-")?.[1]?.trim()
                )
                  ? moment(
                    exp?.current_designation_date?.split("-")?.[1]?.trim(),
                    ["MM, YYYY"]
                  )?.format("MMM YYYY")
                  : "NA"
              }</td></tr>`;
          }
        })
        .join("")
      : `<tr style="width:100%;"><td> - </td></tr>`;

  let previousDesignationHTML =
    data?.expert_experience?.filter((dat) => dat?.previous_designation)
      ?.length > 0
      ? data?.expert_experience
        ?.filter((dat) => dat?.previous_designation)
        ?.map((data) => {
          if (data?.show_check === "yes") {
            return `<tr><td style="font-weight: 500; width:700px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px;">${data?.previous_designation
              }, ${data?.previous_compny
              }</td><td style="font-weight: 500; width:20%;border-bottom:1px solid #c6c6c6;padding:10px;">${!["", "null", null, undefined, "undefined"]?.includes(
                data?.previous_designation_date?.split("-")?.[0]?.trim()
              )
                ? moment(
                  data?.previous_designation_date?.split("-")?.[0]?.trim(),
                  ["MM, YYYY"]
                )?.format("MMM YYYY")
                : "NA"
              } - ${!["", "null", null, undefined, "undefined"]?.includes(
                data?.previous_designation_date?.split("-")?.[1]?.trim()
              )
                ? moment(
                  data?.previous_designation_date?.split("-")?.[1]?.trim(),
                  ["MM, YYYY"]
                )?.format("MMM YYYY")
                : "NA"
              }</td></tr>`;
          } else {
            return `<tr><td style="width:700px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px;">${data?.previous_designation
              }, ${data?.previous_compny
              }</td><td style="width:20%;border-bottom:1px solid #c6c6c6;padding:10px;">${!["", "null", null, undefined, "undefined"]?.includes(
                data?.previous_designation_date?.split("-")?.[0]?.trim()
              )
                ? moment(
                  data?.previous_designation_date?.split("-")?.[0]?.trim(),
                  ["MM, YYYY"]
                )?.format("MMM YYYY")
                : "NA"
              } - ${!["", "null", null, undefined, "undefined"]?.includes(
                data?.previous_designation_date?.split("-")?.[1]?.trim()
              )
                ? moment(
                  data?.previous_designation_date?.split("-")?.[1]?.trim(),
                  ["MM, YYYY"]
                )?.format("MMM YYYY")
                : "NA"
              }</td></tr>`;
          }
        })
        .join("")
      : `<tr style="width:100%;"><td > - </td></tr>`;

  return `<div class="ex_sm_wrppr_pa">
                <div id="ex_sm_wrppr">
                  <div class="ex_sm_wrppr">
                    <div>
                      <div style="text-align:center">
                        <img
                          style="max-width: 300px;width: 100%;"
                          src="${nextyn_logo}"
                          alt="Nextyn Logo"
                        />
                      </div>
                      <div style="text-align:center">
                        <h3>QUALIFIED EXPERT PROFILE</h3>
                      </div>
                      <div>
                        <h5>EXPERT PROFILE NO. ${data?.profile_number || ""
    }</h5>
                      </div>
                    </div>
                    <div>
                        <table style="border: 1px solid #c6c6c6;">
                          <tr>
                            <td style="color: #C41C36;font-weight: 600;background-color: #f2f2f2;width: 300px;border-bottom: 1px solid #c6c6c6;border-right: 1px solid #c6c6c6;padding:10px">
                              <span>Industry/Area of Expertise</span>
                            </td>
                            <td style="border-bottom:1px solid #c6c6c6;padding:10px;">
                              <strong>${data?.industries_expertise}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="color: #C41C36;font-weight: 600;background-color: #f2f2f2;width: 300px;border-bottom: 1px solid #c6c6c6;border-right: 1px solid #c6c6c6;padding:10px">
                              Current Location
                            </td>
                            <td style="border-bottom: 1px solid #c6c6c6;padding:10px;">${data?.current_location
    }</td>
                          </tr>
                          <tr class="tbl">
                            <td style="color: #C41C36;font-weight: 600;background-color: #f2f2f2;width: 300px;border-bottom: 1px solid #c6c6c6;border-right: 1px solid #c6c6c6;padding:10px">
                              Years of Industry Experience
                            </td>
                            <td style="border-bottom: 1px solid #c6c6c6;padding:10px;">${data?.years_of_experience
    }+ Years</Col>
                          </tr>
                          <tr class="tbl">
                            <td style="color: #C41C36;font-weight: 600;background-color: #f2f2f2;width: 300px;border-bottom: 1px solid #c6c6c6;border-right: 1px solid #c6c6c6;padding:10px">
                              <span>
                                Current Designation
                              </span>
                            </td>
                            <td>
                              <table>
                                <tbody>
                                  ${currentDesignationHTML}
                                  </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td style="color: #C41C36;font-weight: 600;background-color: #f2f2f2;width:300px;border-bottom: 1px solid #c6c6c6;border-right: 1px solid #c6c6c6;padding:10px;">
                              <span>
                                Previous Designation
                              </span>
                            </td>
                            <td>
                              <table>
                               ${previousDesignationHTML} 
                              </table>
                            </td>
                          </tr>
                          <tr class="tbl">
                            <td style="color: #C41C36;font-weight: 600;background-color: #f2f2f2;width:300px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px">
                              <span class="h-100 d-flex align-items-center">
                                OverView
                              </span>
                            </td>
                            <td style="border-bottom:1px solid #c6c6c6;padding:10px;width:70%; word-wrap: break-word; word-break: break-all; overflow-wrap: break-word;">
                             ${data?.overview}
                            </td>
                          </tr>
                          <tr class="tbl">
                            <td style="color: #C41C36;font-weight: 600;background-color: #f2f2f2;width: 300px;border-right:1px solid #c6c6c6;padding:10px">
                              Hourly Consulting Rate
                            </td>
                            <td style="padding:10px;">${data?.hourly_consulting_rate
    } ${data?.hourly_currency || "USD"}</td>
                          </tr>
                        </table>
                    </div>
                  </div>
                </div>
              </div>`;
};

// export default ExpertSubmittedProfile;
