import { Button, Form } from "antd";
import React, { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../Constants/api-path";

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [getEmail, setEmail] = useState({
    email: "",
  });
  const [message, setMessage] = useState("");
  const [spinner, setSpinner] = useState(false);

  const [toastClosed, setToastClosed] = useState(false);
  const [toastClosedInvalid, setToastClosedInvalid] = useState(false);
  const inputRef = useRef();

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...getEmail, [name]: value };
    // checkEmail(list.email);
    setEmail(list);
  };

  // const checkEmail = (email) => {
  //   // if (email === "") {
  //   //   return toast.error("Field should not be empty!", {
  //   //     position: toast.POSITION.TOP_RIGHT,
  //   //     autoClose: 3000,
  //   //   });
  //   // }

  //   const regEx = /[/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  //   if (!regEx.test(email)) {
  //     //setChekemail(true)
  //     return setMessage("Email is invalid");
  //   } else {
  //     setMessage("");
  //     let emailID = email.split("@");
  //     if (
  //       emailID[1] === "gmail.com" ||
  //       emailID[1] === "outlook.com" ||
  //       emailID[1] === "yahoo.com" ||
  //       emailID[1] === "inbox.com" ||
  //       emailID[1] === "icloud.com" ||
  //       emailID[1] === "mail.com"
  //     ) {
  //       return setMessage("");
  //     } else {
  //       setMessage("Please Provide Valid Email");
  //     }
  //     //return
  //   }
  // };
  const sendOtpMail = async (e) => {
    e.preventDefault();
    try {
      let { email } = getEmail;
      email = email.toLowerCase();
      if (email === "") {
        return toast.warning("Field should not be empty!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
      setSpinner(true);
      const newProtocol = window.location.protocol;
      const newHost = window.location.host;
      const host = `${newProtocol}//${newHost}`;

      const res = await fetch(`${API_PATH.FORGOT_PASSWORD}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email }),
      });
      const data = await res.json();
      if (data) {
        setSpinner(false);
        if (data.status === 200) {
          inputRef.current.value = "";
          return toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          // if (!toastClosedInvalid) {
          return toast.warning("This user does not exist", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            onClose: () => setToastClosedInvalid(true),
          });
        }
        // }
      }
    } catch (error) {
      setSpinner(true);
      // if (!toastClosed) {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        // onClose: () => setToastClosed(true),
      });
      // }
    }
  };

  // const sendOtpMail = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const { email } = getEmail;
  //     email = email.toLowerCase();
  //     if (email === "") {
  //       return toast.error("Field shouldn't be empty", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     }
  //     setSpinner(true);
  //     const newProtocol = window.location.protocol;
  //     const newHost = window.location.host;
  //     const host = `${newProtocol}//${newHost}`;

  //     const res = await axios.put(`${API_PATH.FORGOT_PASSWORD}`, {
  //       email: email,
  //     });
  //     const data = res.data;
  //     if (data) {
  //       setSpinner(false);
  //       navigate("");
  //       if (data.status === 200) {
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 3000);
  //         return toast.success(data?.msg, {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       } else {
  //         return toast.error("User doesn't exist", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     setSpinner(true);
  //     return toast.error(error?.response?.data?.msg, {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  const something = (event) => {
    if (event.keyCode === 13) {
      sendOtpMail(event);
    }
  };

  return (
    <>
      <Form autoComplete="off">
        <div className="form-group">
          <label> Please enter your registered email</label>
          <input
            ref={inputRef}
            type="text"
            name="email"
            className="form-control"
            autoComplete="none"
            placeholder="Enter Your E-mail Address"
            onChange={handleInputs}
            onKeyDown={(e) => something(e)}
          />
          {/* {message === "" ? <></> : <p className="text-danger">{message}</p>} */}
        </div>

        <div className="form-group">
          <Button className="btn login-btn" onClick={sendOtpMail}>
            Reset Password
          </Button>
        </div>
      </Form>

      <div className="form-group">
        <p className="text-center">
          <NavLink to="/" className="register-btn px-2">
            Login
          </NavLink>
        </p>
      </div>
    </>
  );
}
export default ForgotPasswordForm;
