import React, { useEffect, useState } from "react";
import no_found from "../assests/images/Post-traumatic.svg";
import "./Dashboard.css";
import { createSearchParams, NavLink, useNavigate } from "react-router-dom";
import API_PATH from "../Constants/api-path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AvatarGroup from "react-avatar-group";
import API_URL from "../Constants/api-path";
import { Button } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import RingVolumeOutlinedIcon from "@mui/icons-material/RingVolumeOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import moment from "moment";
import { UpdateProjectModal } from "../components/UpdateProjectModal";
import { setSelectedProject } from "../Redux/features/project.slice";
import { useDispatch } from "react-redux";
import { CreateProjectModal } from "../components/CreateProjectModal";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import UserModal from "../User_management/UserModal";

const Projects = () => {
  const [loading, setLoading] = useState(false);
  const [projectListObj, setProjectListObj] = useState("");
  const history = useNavigate();
  const [ModalToggle, setModalToggle] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState("");
  const [currentModalType, setCurrentModalType] = useState("");
  const [open, setOpen] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [showCreateModal, setCreateModal] = useState(false);

  const handleClose = () => setModalToggle(false);

  const getProjectList = React.useCallback(async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setLoading(true);
    try {
      const res = await fetch(
        `${API_PATH.LIVE_PROJECT}/${userData.user.email}?page=1&limit=${5}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setLoading(false);
        setProjectListObj(result?.data);
      } else {
        setLoading(false);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, []);

  useEffect(() => {
    getProjectList();
  }, [getProjectList]);

  const goToDetailsPage = React.useCallback(
    (project_id) => {
      history({
        pathname: "/projects-details",
        search: `?${createSearchParams({ project_id })}`,
      });
    },
    [history]
  );

  const convertDate = React.useCallback((date) => {
    if (date !== null && date !== "") {
      const convertedDate = moment(date).format("MMM Do, YYYY");
      return convertedDate;
    } else {
      return "---------";
    }
  }, []);

  const Calculate_days = React.useCallback((date1) => {
    var diff = new Date().getTime() - new Date(date1).getTime();
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (days !== 0) {
      if (days < 0) {
        return Math.abs(days) + " " + "days left ";
      } else {
        return days + " " + "days ago ";
      }
    } else {
      return "Today";
    }
  }, []);

  const CalculatePercentage = React.useCallback((total, percentage) => {
    const completedCalls = parseFloat(total);
    return parseInt((percentage / completedCalls) * 100);
  }, []);

  const customizeChatCount = React.useCallback((chatCount) => {
    chatCount = parseInt(chatCount);
    if (chatCount > 9) {
      return "9+";
    } else {
      return chatCount;
    }
  }, []);

  const getConforMationModal = React.useCallback((project_id, modalType) => {
    setCurrentProjectId(project_id);
    setCurrentModalType(modalType);
    setModalToggle(true);
  }, []);
  const dispatch = useDispatch();
  const getDropDownItem = React.useCallback(
    (object, status, _id) => {
      if (status === 1 || status === 0) {
        return (
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => {
                  dispatch(setSelectedProject(object));
                  setOpen(true);
                }}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Update</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => goToDetailsPage(_id)}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>View</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "hold")}
              >
                <i className="fa fa-ban" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Put on hold</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "complete")}
              >
                <i className="fa fa-check" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Completed</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "delete")}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Delete</span>
              </span>
            </li>
          </ul>
        );
      } else if (status === 2) {
        return (
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => {
                  dispatch(setSelectedProject(object));
                  setOpen(true);
                }}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Update</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => goToDetailsPage(_id)}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>View</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "resume")}
              >
                <i class="fa fa-retweet" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Resume</span>{" "}
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "complete")}
              >
                <i class="fa fa-check" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Completed</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "delete")}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Delete</span>
              </span>
            </li>
          </ul>
        );
      } else if (status === 3) {
        return (
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => {
                  dispatch(setSelectedProject(object));
                  setOpen(true);
                }}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Update</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => goToDetailsPage(_id)}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>View</span>{" "}
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "delete")}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Delete</span>
              </span>
            </li>
          </ul>
        );
      } else {
        return (
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => {
                  dispatch(setSelectedProject(object));

                  setOpen(true);
                }}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Update</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "resume")}
              >
                <i class="fa fa-retweet" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Resume</span>{" "}
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "complete")}
              >
                <i class="fa fa-check" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Completed</span>
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => goToDetailsPage(_id)}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>View</span>{" "}
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => getConforMationModal(_id, "delete")}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                <span style={{ marginLeft: "10px" }}>Delete</span>
              </span>
            </li>
          </ul>
        );
      }
    },
    [dispatch, getConforMationModal, goToDetailsPage]
  );

  const changeProjectStatus = React.useCallback(async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    };
    let status = 0;
    if (currentModalType === "hold") {
      status = 4;
    }
    if (currentModalType === "resume") {
      status = 0;
    }
    if (currentModalType === "complete") {
      status = 3;
    }
    if (currentModalType === "delete") {
      status = 2;
    }
    const resp = await fetch(`${API_URL.PRJECT_STATUS_CHANGE}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        project_id: currentProjectId,
        status: status,
      }),
    });
    const result = await resp.json();
    if (result) {
      setModalToggle(false);
      getProjectList()
      if (currentModalType === "delete") {
        return toast.success("Project deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.success("Status updated succesfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  }, [currentModalType, currentProjectId, getProjectList]);

  const getLiveProjectListTable = React.useCallback(() => {
    if (projectListObj instanceof Array) {
      return projectListObj.map(function (object, i) {
        const {
          _id,
          project_title,
          completed_Calls,
          status,
          expected_Submitted,
          expected_calls,
          project_id,
          chat_count,
          project_deadline,
          created_at,
          invite_team,
        } = object;
        return (
          <tr className="my-4" key={i}>
            <td valign="middle">
              <div onClick={() => goToDetailsPage(_id)} className="">
                <h6
                  className="projectTitle"
                  title={project_title}
                  style={{
                    color: "#526fd7",
                  }}
                >
                  {" "}
                  {project_title ? project_title : "---------"}
                </h6>
                <div className=" d-flex">
                  <img src="images/expert-icon1.svg" alt="sn" width="17px" />
                  <span className="px-2 createdDate">
                    {" "}
                    {convertDate(created_at)}
                  </span>
                </div>
              </div>
            </td>
            <td valign="middle" align="center" style={{ paddingTop: "20px" }}>
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{
                    width: `${CalculatePercentage(
                      expected_calls,
                      completed_Calls
                    )}%`,
                  }}
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <ul className="d-flex number-list align-items-center list-unstyled">
                <li>
                  <span>
                    <LocalPhoneOutlinedIcon style={{ fontSize: "18px" }} /> :
                  </span>
                  {expected_calls ? expected_calls : 0}
                </li>

                <li>
                  <span>
                    <PersonAddAlt1OutlinedIcon style={{ fontSize: "18px" }} /> :
                  </span>
                  {expected_Submitted ? expected_Submitted : 0}
                </li>

                <li>
                  <span>
                    <RingVolumeOutlinedIcon style={{ fontSize: "18px" }} /> :
                  </span>

                  {completed_Calls
                    ? completed_Calls
                    : completed_Calls
                      ? completed_Calls
                      : 0}
                </li>
              </ul>
            </td>
            <td className="date" valign="middle">
              <h6>{convertDate(project_deadline)}</h6>
              <p> {Calculate_days(project_deadline)} </p>
            </td>
            <td valign="middle" className="avatarImg">
              <AvatarGroup
                avatars={invite_team?.split(",")}
                uppercase={true}
                initialCharacters={2}
                fontColor="#f33636"
                max={2}
                size={30}
                fontSize=".38"
                displayAllOnHover={false}
                backgroundColor="#EFEFEF"
              />
              {/* <div className="number-member">
                <h6> {team.length} Members </h6>
              </div> */}
            </td>
            {/* <td className="nm1" valign="middle">
              <div>
                {chat_count ? (
                  <NavLink
                    to={{
                      pathname: "/projects-details-message",
                      search: `?${createSearchParams({ project_id })}`,
                    }}
                  >
                    <img
                      src={messageColoredSvg}
                      className="me-1"
                      loading="lazy"
                    />
                    <span style={{ fontSize: "14px" }}>
                      {customizeChatCount(chat_count)}
                    </span>
                  </NavLink>
                ) : (
                  <>
                    <img src={messageSvg} />
                  </>
                )}
              </div>
            </td> */}

            <td valign="middle">
              <BsThreeDotsVertical
                className="btn p-0 border-0 "
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              />

              {getDropDownItem(object, status, _id)}
            </td>
          </tr>
        );
      });
    } else {
      <> </>;
    }
  }, [CalculatePercentage, Calculate_days, convertDate, getDropDownItem, goToDetailsPage, projectListObj]);
  return (
    <>
      <div className="body-expart-div">
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <h4 style={{ fontSize: "20px" }}>
              <strong className="fw-normal" style={{ color: "#5746ec" }}>
                Projects
              </strong>
            </h4>
            {projectListObj?.length > 0 && <span >
              <button className="btn viewButton" onClick={() => {
                setOpenProject(true)
              }}>Create Project</button>
            </span>}
          </div>
          <div>
            {projectListObj?.length > 0 && !loading &&
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th>Project name</th>
                    <th style={{ width: "250px", textAlign: "center" }}>
                      Progress
                    </th>
                    <th style={{ width: "170px" }}>deadline</th>
                    <th style={{ width: "150px" }}>team</th>
                    {/* <th style={{ width: "70px" }}>Chat</th> */}
                    <th className="noafter" style={{ width: "55px" }}></th>
                  </tr>
                </thead>
                <tbody>{getLiveProjectListTable()}</tbody>
              </table>
            }
            {loading && <div className="text-center noResultFound">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-6"> <LoadingOutlined style={{
                    color: "#5746EC",
                    fontSize: 20
                  }} /> </div>
                </div>
              </div>
            </div>}
            {projectListObj?.length === 0 && !loading &&

              <div className="text-center noResultFound">
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6">
                      <img
                        src={no_found}
                        alt="sv"
                        style={{ height: "400px", marginTop: "50px" }}
                      ></img>
                    </div>
                    <div className="col-md-6">
                      <h3
                        style={{
                          fontSize: "18px",
                          textAlign: "left",
                          fontWeight: "300",
                          lineHeight: "26px",
                        }}
                      >
                        <span>Oops,</span> looks like you haven't launched a
                        custom project yet.
                        <br />
                        <p className="mt-3">
                          Please{" "}
                          <NavLink style={{ fontWeight: "500", color: "#5746EC" }} onClick={() => {
                            setOpenProject(true)
                          }}>
                            click here
                          </NavLink>{" "}
                          to create a new project.
                        </p>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="form-group">
          <Modal

            title={<>
              {currentModalType === "resume" ? (
                <>
                  <h4 className="mb-0">Resume</h4>
                </>
              ) : (
                ""
              )}
              {currentModalType === "delete" ? (
                <>
                  <h4 className="mb-0">Are you sure?</h4>
                </>
              ) : (
                ""
              )}
              {currentModalType === "complete" ? (
                <>
                  <h4 className="mb-0">Complete</h4>
                </>
              ) : (
                ""
              )}
              {currentModalType === "hold" ? (
                <>
                  <h4 className="mb-0">Hold</h4>
                </>
              ) : (
                ""
              )}
            </>}
            open={ModalToggle}
            onCancel={handleClose}
            footer={<div
              className="d-flex justify-content-end gap-2"
            >
              {currentModalType === "resume" ? (
                <>
                  <Button className="btn btn-light" onClick={handleClose}>
                    No
                  </Button>
                  <Button
                    className="btn btn-primary"
                    style={{ backgroundColor: "#5746ec", border: "none" }}
                    onClick={changeProjectStatus}
                  >
                    Yes
                  </Button>
                </>
              ) : (
                ""
              )}

              {currentModalType === "hold" ? (
                <>
                  <button className="btn btn-light" onClick={handleClose}>
                    No
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{ backgroundColor: "#5746ec", border: "none" }}
                    onClick={changeProjectStatus}
                  >
                    Yes
                  </button>
                </>
              ) : (
                ""
              )}

              {currentModalType === "delete" ? (
                <>
                  <button className="btn btn-light mr-4" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary ml-4"
                    style={{ backgroundColor: "#5746ec", border: "none" }}
                    onClick={changeProjectStatus}
                  >
                    Delete
                  </button>
                </>
              ) : (
                ""
              )}

              {currentModalType === "complete" ? (
                <>
                  <button className="btn btn-light" onClick={handleClose}>
                    No
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{ backgroundColor: "#5746ec", border: "none" }}
                    onClick={changeProjectStatus}
                  >
                    Yes
                  </button>
                </>
              ) : (
                ""
              )}
            </div>}
            width={600}
            centered
          >
            <>
              <div style={{ width: "100%", textAlign: "left" }}>
                {currentModalType === "resume" ? (
                  <>
                    <p className="mb-0">
                      {" "}
                      Are you sure you want to {currentModalType} this project
                    </p>
                  </>
                ) : (
                  ""
                )}
                {currentModalType === "hold" ? (
                  <>
                    <p className="mb-0">
                      {" "}
                      Are you sure you want to put this project on {currentModalType}?
                    </p>
                  </>
                ) : (
                  ""
                )}

                {currentModalType === "complete" ? (
                  <>
                    <p className="mb-0">
                      {" "}
                      Are you sure you want to {currentModalType} this project{" "}
                    </p>
                  </>
                ) : (
                  ""
                )}

                {currentModalType === "delete" ? (
                  <>
                    <p className="mb-0">
                      Do you really want to delete these records? This process
                      cannot be undone.
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>

          </Modal>
        </div>
      </div>
      <UpdateProjectModal
        open={open}
        setOpen={setOpen}
        getProjectList={getProjectList}
        setCreateModal={setCreateModal}
      />
      <CreateProjectModal
        open={openProject}
        setOpen={setOpenProject}
        getProjectList={getProjectList} setCreateModal={setCreateModal} />

      <UserModal showCreateModal={showCreateModal} setCreateModal={setCreateModal} type="create" />
    </>
  );
};
export default Projects;
