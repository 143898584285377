export const convertToHtmlTranscript = (recordingData) => {
  function doubleDigit(digit) {
    if (digit < 10) {
      return "0" + digit;
    } else {
      return digit;
    }
  }
  function convertMS(time) {
    var hour = "";
    var min = Math.floor((time / (1000 * 60)) % 60),
      hours = Math.floor((time / (1000 * 60 * 60)) % 24),
      sec = Math.floor((time / 1000) % 60);
    if (hours) {
      hour = `${doubleDigit(hours)}:`;
    }
    return `${hour}${doubleDigit(min)}:${doubleDigit(sec)}`;
  }
  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString}`;
      return p;
    } else {
      return "-";
    }
  };
  function replaceTxt(txt, para) {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }
  return `<div id="scriptBody" style="width: 880px">
  <h6 style="text-align:center;color:#5746ec;font-weight:600">${recordingData?.companyName
    } - ${replaceTxt(recordingData?.topic, [
      "nextyn",
      "micro",
      "consulting",
      "-consulting",
      "call - ",
      "call",
      // "-",
    ])?.toUpperCase()} - ${convertDate(recordingData?.recording_start)}</h6>
  <div style="height:1px; background: #000"></div>
    ${recordingData?.assembly_response?.utterances?.map(
      (value) => `<div style='position:relative;margin-top:20px;'>
                      <div>
                          <h6 style='margin-bottom: 1;display:flex;align-items:center;font-weight:600;color:#000;font-size: 12px'>
                              <strong>Speaker ${value.speaker}</strong>
                              <span style='color:#8294a5;font-size:12px;font-weight:400;display:inline-block;margin-left: 14px'>
                                  ${convertMS(value?.end)}
                              </span>
                          </h6>
                          <p style='font-size:12px;color:#05294b;cursor:pointer';>${value.text
        }</p>
                      </div>
                  </div>`
    )}
    </div>`;
};

export const getModifiedDescription = (description) => {
  let bulletPoints;
  bulletPoints = description?.replaceAll("-", "");
  bulletPoints = description?.split("\n");
  return bulletPoints;
};

export const getMonthName = (monthNum) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const index = parseInt(monthNum, 10) - 1;
  return monthNames[index];
};
export const convertDate = (date) => {
  if (
    date !== null &&
    date !== "" &&
    date !== "null" &&
    date !== "null - " &&
    date !== "null - null"
  ) {
    // Check if the date is in the format "08, 2023 - 09, 2023"
    if (/^\d{2}, \d{4} - \d{2}, \d{4}$/.test(date)) {
      const [startDate, endDate] = date.split(" - ");
      const [startMonthNum, startYear] = startDate.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // Check if the date is in the format "08, 2023 - "
    if (/^\d{2}, \d{4} - $/.test(date)) {
      const [startDate] = date.split(" - ");
      const [startMonthNum, startYear] = startDate.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // Check if the date is in the format "08, 2023 "
    if (/^\d{2}, \d{4}$/.test(date)) {
      const [startMonthNum, startYear] = date.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // Check if the date is in the format "2018-04"
    if (/^\d{4}-\d{2}$/.test(date)) {
      const [year, monthNum] = date.split("-");
      const month = getMonthName(monthNum);
      return `${month}, ${year}`;
    }

    // Check if the date is in the format "10, 2014 - 01, 2020"
    if (/^\d{2}, \d{4}\s*-\s*\d{2}, \d{4}$/.test(date)) {
      const [startDate, endDate] = date.split(" - ");
      const [startMonthNum, startYear] = startDate.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // Check if the date is in the format "02, 2018   -    "
    if (/^\d{2}, \d{4}\s*-\s*$/.test(date)) {
      const [startDate] = date.split(" - ");
      const [startMonthNum, startYear] = startDate.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // For other formats, convert the date to the desired format
    var d = new Date(date);
    var day = d.getDate();
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = monthNames[d.getMonth()];
    var year = d.getFullYear();
    let formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  } else {
    return "";
  }
};

export const getFormattedDate = (date) => {
  if (!date || typeof date !== "string") {
    return null;
  }

  const dates = date.split(" - ");
  const startDate = convertDate(dates[0]?.trim(), "month");
  const endDate = dates[1] ? convertDate(dates[1]?.trim(), "month") : "";

  return `${startDate} - ${endDate}`;
};
export const statusSetter = (status) => {
  if (status === "Edit") {
    return (
      <p className="mb-0">
        Are you sure you want to schedule a call with this expert? Once you
        confirm, our team will get in touch with you to lock in a convenient day
        and time for the same.{" "}
      </p>
    );
  } else if (status === "Request") {
    return (
      <p className="mb-0">
        Are you sure, you want to mark this call as Requested?
      </p>
    );
  } else if (status === "Schedule") {
    return (
      <p className="mb-0">
        Are you sure, you want to mark this call as Scheduled?
      </p>
    );
  } else if (status === "Complete") {
    return (
      <p className="mb-0">
        Are you sure, you want to mark this call as completed?
      </p>
    );
  } else if (status === "Decline") {
    return (
      <p className="mb-0">
        Are you sure, you want to mark this call as Declined?
      </p>
    );
  } else if (status === "Delete") {
    return (
      <p className="mb-0">Are you sure, you want to delete this expert?</p>
    );
  }
};

export const statusButton = (status) => {
  if (status === "Edit" || status === "Edit") {
    return "Edit";
  } else if (status === "Request") {
    return "Request";
  } else if (status === "Schedule") {
    return "Schedule";
  } else if (status === "Complete") {
    return "Complete";
  } else if (status === "Decline") {
    return "Decline";
  } else if (status === "Delete") {
    return "Delete";
  }
};
//   if (!data?.expert_experience || data.expert_experience.length === 0) {
//     return [];
//   }

//   const experienceList = data.expert_experience.map((experience) => {
//     const obj = {};
//     obj.previous_designation = experience.previous_designation || "";
//     obj.previous_compny = experience.previous_compny || "";
//     obj.previous_date = getFormattedDate(
//       experience.previous_designation_date
//     );

//     // Filter out entries with invalid or empty previous_date
//     if (!obj.previous_date || obj.previous_date === "NaN undefined, NaN") {
//       return null;
//     }

//     return obj;
//   });

//   // Filter out null entries to remove the invalid data
//   const filteredExperienceList = experienceList.filter(
//     (item) => item !== null
//   );

//   return filteredExperienceList;
// };

export const modifiedContent = (cntnt) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(cntnt, "text/html");

  // Find all anchor tags within the parsed document
  const anchors = doc.querySelectorAll("a");

  // Add the target="_blank" attribute to each anchor tag
  anchors.forEach((anchor) => {
    let href = anchor.getAttribute("href");

    // Check if href doesn't start with 'https'
    if (href && !href.startsWith("https")) {
      // If href starts with 'http', replace with 'https'
      if (href.startsWith("http")) {
        href = href.replace(/^http:/, "https:");
      }
      // If href doesn't start with any protocol, add 'https://'
      else if (!href.startsWith("//") && !href.startsWith("/")) {
        href = `https://${href}`;
      }

      // Set the modified href back to the anchor tag
      anchor.setAttribute("href", href);
    }

    // Set target="_blank" to open the link in a new tab
    anchor.setAttribute("target", "_blank");
  });

  cntnt = doc.body.innerHTML;

  return cntnt;
};

export const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

export const columns = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "20%"
  },
  {
    title: "Most Relevant Title",
    dataIndex: "title",
    key: "title",
    width: "20%"
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    width: "30%"
  }, {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
    width: "10%"
  }, {
    title: "Added On",
    dataIndex: "added_on",
    key: "added_on",
    width: "10%"
  }, {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "30%"
  },
]