import JoditEditor from "jodit-react";
import React, { useRef } from "react";
const MailEditor = ({ setValue, config,value }) => {
  const editor = useRef("#editor", {
    autofocus: false,
    height: "200",
    toolbarAdaptive: false,
  });

  return (
    <JoditEditor
      ref={editor}
      onBlur={content => setValue(content)} // preferred to use only this option to update the content for performance reasons
      onChange={(content) => setValue(content)}
      config={config}
      tabIndex={1}
      value={value}
    />
  );
};
export default MailEditor;
